import { useEffect, useState } from "react";
import { readDocs } from "../../api";
import _ from "lodash";
export default function Store({ stores, country }) {
  const [config, setConfig] = useState({});
  const [configs, setConfigs] = useState([]);
  const [showCreate, setShowCreate] = useState(false)

  useEffect(() => {
    readDocs({
      path: `/setting`,
      setData: setConfigs,
    });
  }, []);

  const updateConfig = (e) => {
    e.preventDefault();
  };

  const getField = (e) => {
    setConfig((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const findStore = (id) => _.find(stores, (st) => st._id === id)?.name || "";
  return (
    <div className="flex w-full flex-1 p-2">

      <div className="flex-1 px-5">
        <div className="w-full flex items-center justify-between py-5">
          <p className="font-medium text-lg">Manage Discounts.</p>
          <button onClick={() => setShowCreate(true)} className="bg-yellow-500 px-5 py-2 rounded text-sm text-white font-semibold">
            Create Discount
          </button>
        </div>

        <div className="py-2 align-middle inline-block min-w-full">
          <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Store
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Discount
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Delivery Fee
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Free At
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Auto Accept
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {configs?.map((str, idx) => (
                  <tr key={idx}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">

                        <div className="">
                          <div className="text-sm font-medium text-gray-900">{findStore(str.storeID)}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{str.discount}%</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {country?.currencySymbol + str.deliveryCharge}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{country?.currencySymbol + str?.freeDeliveryAt}{" "}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{str?.autoAcceptRequest ? "Yes" : "No"}</td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {showCreate && <div onClick={e=>setShowCreate(false)} className="bg-black/50 z-10 backdrop-blur-sm absolute top-0 left-0 right-0 bottom-0">
        <div onClick={e=>e.stopPropagation()} className="w-1/3 mx-auto mt-10 flex flex-col space-y-5 rounded p-3 bg-white">
          <div className=" gap-2 flex flex-col">
            <div className="flex items-center justify-between">
              <label>Store Discount</label>
            </div>
            <div className="border flex">
              <input
                value={config?.discount}
                name="discount"
                min="1"
                onChange={getField}
                type="number"
                className=" p-3 flex-1 outline-none"
              />
            </div>
          </div>

          <div className=" flex flex-col space-y-5">
            <div className="flex items-center space-x-2">
              <div className="flex flex-col space-y-2 flex-1">
                <label>Delivery Type:</label>
                <select
                  onChange={getField}
                  value={config?.deliveryType}
                  name="deliveryCharge"
                  className="border p-3 outline-none"
                >
                  <option value="Delivery">Delivery</option>
                  <option value="Self Pickup">Self Pickup</option>
                </select>
              </div>
              <div className="flex flex-col space-y-2 flex-1">
                <label>Delivery Charge Type:</label>
                <select
                  value={config?.deliveryChargeType}
                  onChange={getField}
                  name="deliveryChargeType"
                  className="border p-3 outline-none"
                >
                  <option value="Dynamic">Dynamic</option>
                  <option value="Fixed">Fixed</option>
                </select>
              </div>
            </div>
            <div className="flex items-center space-x-2">
            <div className="flex flex-col space-y-2 flex-1">
              <label>Delivery Charge:</label>
              <input
                onChange={getField}
                name="deliveryCharge"
                min={1}
                value={config?.deliveryCharge}
                type="number"
                className="p-3 border outline-none"
              />
            </div>
            <div className="flex flex-col space-y-2 flex-1">
              <label>Free Delivery Threshold:</label>
              <input
                onChange={getField}
                name="freeDeliveryAt"
                min="1"
                value={config?.freeDeliveryAt}
                type="number"
                className="p-3 border outline-none"
              />
            </div>
              </div>
            
            <button
              onClick={updateConfig}
              className="bg-yellow-500 font-medium rounded-md text-white p-3"
            >
              Save
            </button>
          </div>
        </div>
      </div>}
    </div>
  );
}
