import React, { useEffect, useState } from "react";
import { HiPlusSmall } from "react-icons/hi2";
import _ from "lodash";
import AddStore from "../forms/AddStore";
import { AnimatePresence } from "framer-motion";
import { readDocs, socket } from "../api";
import moment from "moment/moment";
import { useOutletContext } from "react-router-dom";
import StoreProfile from "../components/StoreProfile";
import Header from "../components/Header";
import { HiViewGrid, HiViewList } from "react-icons/hi";
import { IoFastFood } from "react-icons/io5";

export default function Stores() {
  const [show, setShow] = useState(false);
  const [stores, setStores] = useState([]);
  const [orders, setOrders] = useState([]);
  const [items, setItems] = useState([]);
  const [store, setStore] = useState(null);
  const [call, setCall] = useState(0);
  const { country } = useOutletContext();

  const [dataView, setDataView] = useState(0);

  socket.on("store-added", () => setCall(new Date().getSeconds()));

  useEffect(() => {
    readDocs({
      path: `/store/all/${country?.name}?call=${call}`,
      setData: setStores,
    });

    readDocs({
      path: `/order/all/${country?.name}`,
      setData: setOrders,
    });

    readDocs({
      path: `/food/all/${country?.name}`,
      setData: setItems,
    });
  }, [call, country]);

  const getStats = (store_id) => {
    const totalOrders = _.filter(orders, (ord) => ord.storeID === store_id);
    const totalFoods = _.filter(
      items,
      (ord) => ord.storeID === store_id
    ).length;
    const earnings = _.filter(totalOrders, (rd) =>
      ["Cancelled", "Open", "Refund"].includes(rd.status)
    );
    const sum = _.sumBy(earnings, (ern) => ern.storePrice);

    return [
      totalOrders.length,
      totalFoods,
      country?.currencySymbol + Number(sum.toFixed(2)).toLocaleString(),
    ];
  };

  return (
    <div className="flex-1">
      <div className="flex justify-between items-center p-5">
        <Header
          title={"Manage Stores"}
          description={
            "Get an indepth view on the orders made buy users on the system and also sort them based on the order status and restaurnat specific orders."
          }
        />
        <button
          onClick={() => setShow(!show)}
          className="p-2 bg-red-500 rounded-full text-white"
        >
          <HiPlusSmall size={20} color={"white"} />
        </button>
      </div>
      <div className="flex flex-row  px-5">
        <div className="flex items-center bg-gray-100 px-3 rounded-md space-x-5 py-2">
          <div
            onClick={() => setDataView(0)}
            className={
              dataView === 0
                ? "bg-white p-3 rounded-md transition-color "
                : "transition-colors pl-2 cursor-pointer"
            }
          >
            <HiViewList size={16} />
          </div>
          <div
            onClick={() => setDataView(1)}
            className={
              dataView === 1
                ? "bg-white p-3 rounded-md transition-color "
                : "transition-colors r-2 cursor-pointer"
            }
          >
            <HiViewGrid size={16} />
          </div>
        </div>
      </div>

      {dataView === 0 && (
        <div className="flex-1 px-5">
          <table className="table-auto w-full mt-2">
            <thead>
              <tr className="border-y bg-gray-200">
                <th className="text-left font-semibold p-2 text-sm text-gray-500">
                  Restaurant
                </th>
                <th className="text-left font-semibold p-2 text-sm text-gray-500">
                  Orders
                </th>
                <th className="text-left font-semibold p-2 text-sm text-gray-500">
                  Foods
                </th>
                <th className="text-left font-semibold p-2 text-sm text-gray-500">
                  Income
                </th>
                <th className="text-left font-semibold p-2 text-sm text-gray-500">
                  Joined
                </th>
              </tr>
            </thead>
            <tbody>
              {stores?.map((info, idx) => (
                <tr
                  className="border-b py-2 cursor-pointer"
                  key={idx}
                  onClick={() => setStore(info)}
                >
                  <td className="p-2 text-sm py-3 flex gap-3 flex-row items-center text-green-700">
                    <img
                      alt={info?.name}
                      src={info?.photo}
                      className="w-10 object-cover rounded-md h-10 bg-green-500"
                    ></img>
                    <div>
                      <p className="font-bold">{info.name}</p>
                      <p className="font-bold text-xs text-gray-400">
                        {info?.email}
                      </p>
                    </div>
                  </td>
                  <td
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      // navigate to user info
                    }}
                    className="p-2 text-sm py-3 text-blue-800"
                  >
                    {getStats(info._id)[0]}
                  </td>
                  <td className="p-2 text-sm py-3">{getStats(info._id)[1]}</td>
                  <td className="p-2 text-sm py-3">{getStats(info._id)[2]}</td>
                  <td className="p-2 text-sm py-3 font-light text-gray-600">
                    {new Date(info?.createdAt).toLocaleDateString("ru")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {dataView === 1 && (
        <div className="flex gap-5  w-full flex-wrap p-5">
          {stores?.map((store, idx) => (
            <Store
              key={idx}
              store={store}
              setStore={setStore}
              foods={getStats(store._id)[1]}
            />
          ))}
        </div>
      )}

      <AnimatePresence>
        {show && (
          <AddStore
            close={() => {
              setShow(false);
              setStore(null);
            }}
            company={store}
          />
        )}
        {store && show === false && (
          <StoreProfile store={store} setStore={setStore} setShow={setShow} />
        )}
      </AnimatePresence>
    </div>
  );
}

const Store = ({ store, setStore, foods }) => (
  <div
    onClick={() => setStore(store)}
    className="flex relative cursor-pointer flex-col md:w-[45.5%] lg:w-[23%] border overflow-hidden rounded-md shadow-md"
  >
    <img
      alt={store?.name}
      src={store?.photo}
      className="w-full object-cover h-32 bg-white-500"
    ></img>
    <div className="flex flex-col p-3 space-y-2">
      <p className="text-md text-gray-600 font-semibold">{store?.name}</p>
      <div className="flex flex-col gap-2">
        <p className=" text-xs text-gray-400">{store?.fullAddress}</p>
        <p className="text-sm truncate">
          Created: {moment(store?.createdAt).from()}
        </p>
      </div>
    </div>
    <div className="flex items-center space-x-2 flex-row absolute top-3 left-3 bg-white/50 px-3 rounded-full py-2 ">
      <IoFastFood />
      <p className="text-xs font-medium">{foods}</p>
    </div>
  </div>
);
