import React, { useEffect, useState } from "react";
import { HiOutlineChartPie } from "react-icons/hi";
import { readDocs } from "../api";
import _ from "lodash";
import { useOutletContext } from "react-router-dom";
import MonthlyVisuals from "../components/graphs/MonthlyVisuals";
import moment from "moment";
import { maxDate } from "../functions";
import { AnimatePresence, motion } from "framer-motion";

export default function Finance() {
  const [orders, setOrders] = useState([]);
  const [stores, setStores] = useState([]);
  const [show, setShow] = useState(false);
  const [details, setDetails] = useState({});
  const [time, setTime] = useState({
    from: new Date().toLocaleString(),
    to: maxDate(),
  });
  const { country } = useOutletContext();

  const orderStatus = [
    "Ready",
    "Cancelled",
    "Refund",
    "Preparing",
    "Completed",
  ];

  useEffect(() => {
    readDocs({
      path: `/order/all/${country?.name}`,
      setData: setOrders,
    });

    readDocs({
      path: `/store/all/${country?.name}`,
      setData: setStores,
    });
  }, [country?.name]);

  const getNumber = (status) => {
    const thisOrder = orders;
    const filtered = _.filter(thisOrder, (ord) => ord.status === status);
    return (
      country?.currencySymbol + " "+
      Number(
        _.sumBy(filtered, (ft) => ft.totalPrice).toFixed(2)
      ).toLocaleString()
    );
  };

  const filter = (date) => {
    const start = moment(time.from);
    const end = moment(time.to);

    return moment(date).isBetween(start, end, "[]");
  };

  const getRanger = () => {
    const range_orders = _.filter(orders, (ord) =>
      filter(new Date(ord.createdAt))
    );

    const storeOrders = _.filter(
      range_orders,
      (ord) => !["Cancelled", "Refund"].includes(ord.status)
    );

    const grouped = _.groupBy(storeOrders, (st) => st.storeID);

    const data = [];

    Object.entries(grouped).forEach(([key, values]) => {
      data.push({
        id: key,
        store: _.find(stores, (st) => st._id === key)?.name,
        earnings:
          country.currencySymbol +
          Number(
            _.sumBy(values, (vl) => vl.storePrice).toFixed(2)
          ).toLocaleString(),
      });
    });

    return data;
  };

  const earningDetails = (store_id) => {
    let store = _.find(stores, (st) => st._id === store_id);
    setShow(true);
    const results = _.filter(
      orders,
      (ord) =>
        ord.storeID === store_id &&
        filter(ord.createdAt) &&
        !["Cancelled", "Refund"].includes(ord.status)
    );
    setDetails({
      store: store,
      data: results,
    });
  };

  return (
    <div className="flex-1 h-full  overflow-hidden p-2 flex flex-col gap-2">
      <div className="flex-1  gap-4 flex flex-col space-y-10 lg:space-y-0 lg:flex-row overflow-hidden">
        <div className="lg:w-1/3 gap-2 h-full flex flex-col overflow-y-auto">
          <div className="flex gap-2">
            <InfoCard desc={orderStatus[0]} value={getNumber(orderStatus[0])} />
            <InfoCard desc={orderStatus[1]} value={getNumber(orderStatus[1])} />
          </div>
          <div className="flex gap-2">
            <InfoCard desc={orderStatus[2]} value={getNumber(orderStatus[2])} />
            <InfoCard desc={orderStatus[3]} value={getNumber(orderStatus[3])} />
          </div>

          <div className="flex flex-col space-y-3 mt-5">
            <div className=" py-2 ">
              <p className="leading-relaxed text-md font-bold">
                Filter By Date
              </p>
              <p className="text-sm text-gray-400">
                Provide time interval for order records
              </p>
            </div>
            <form action="#" className="flex w-full gap-2 ">
              <div className="flex flex-col w-1/2">
                <label htmlFor="from">From</label>
                <input
                  type="date"
                  value={time.from}
                  max={maxDate()}
                  onChange={(e) =>
                    setTime((prev) => ({ ...prev, from: e.target.value }))
                  }
                  id="from"
                  className="border p-2 bg-white rounded-md outline-none"
                />
              </div>
              <div className="flex flex-col w-1/2">
                <label htmlFor="to">To</label>
                <input
                  type="date"
                  value={time.to}
                  max={maxDate()}
                  onChange={(e) =>
                    setTime((prev) => ({ ...prev, to: e.target.value }))
                  }
                  id="to"
                  className="border p-2 bg-white rounded-md outline-none"
                />
              </div>
            </form>
            <table className="table-fixed w-full mt-3 ">
              <thead>
                <tr className="bg-orange-50 border-y text-gray-500 text-sm">
                  <td className="p-2 font-semibold">Store</td>
                  <td className="p-2 font-semibold">Earnings</td>
                </tr>
              </thead>
              <tbody>
                {getRanger()?.map((info, idx) => (
                  <tr
                    className="border-b cursor-pointer"
                    key={idx}
                    onClick={() => earningDetails(info.id)}
                  >
                    <td className="p-3">{info.store}</td>
                    <td className="p-3">{info.earnings}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex-1 flex flex-col px-3 space-y-5 lg:overflow-y-auto">
          <div className="flex w-full items-center bg-orange-50/50 backdrop-blur-sm rounded-sm justify-between  p-5 flex-col ">
            <MonthlyVisuals orders={orders} />
          </div>
        </div>

        {/* show modal from here... */}
        <AnimatePresence>
          {show && (
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              exit={{ y: 100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="w-5/6 shadow-md relative bg-white h-[90%] p-3"
            >
              <div className="p-3 flex flex-col">
                <h3>Successful Order History - {details?.store?.name}</h3>
                <p className="text-gray-600 text-sm">
                  Successful Order History from ({time.from}) to ({time.to}){" "}
                </p>
              </div>
              <table className="table-auto w-full text-sm">
                <thead className="border-y">
                  <tr>
                    <th className="p-3 text-left">ID</th>
                    <th className="p-3 text-left">Price</th>
                    <th className="p-3 text-left">Commission</th>
                    <th className="p-3 text-left">Service Charge</th>
                    <th className="p-3 text-left">Delivery</th>
                    <th className="p-3 text-left">Status</th>
                    <th className="p-3 text-left">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {details?.data.map((info, idx) => (
                    <tr
                      key={idx}
                      className={`${
                        idx % 2 === 0 ? "bg-gray-100" : "bg-white"
                      }`}
                    >
                      <td className="p-3">#{info.id}</td>
                      <td className="p-3 font-semibold">
                        {" "}
                        {country.currencySymbol}{" "}
                        {Number(info.storePrice).toFixed(2)}
                      </td>
                      <td className="p-3">
                        {" "}
                        {country.currencySymbol}{" "}
                        {Number(info.commission || 0 ).toFixed(2)}
                      </td>
                      <td className="p-3">
                      {country.currencySymbol}{" "}
                      {Number(info.serviceCharge).toFixed(2)}
                      </td>
                      <td className="p-3">
                        {" "}
                        {country.currencySymbol +
                          (info.deliveryCharge || " N/A")}
                      </td>
                      <td className="p-3"> {info.status}</td>
                      <td className="p-3"> {info.createdAt?.slice(0, 10)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button
                onClick={() => setShow(false)}
                className="text-white bg-red-600 text-sm font-normal absolute bottom-5 left-3 text-center w-fit px-3 py-1"
              >
                Close
              </button>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

const InfoCard = ({ desc, value }) => (
  <div className="flex items-center p-2 gap-2 w-1/2  h-24 bg-indigo-50 rounded-md">
    <div className="w-12 h-12 flex items-center justify-center rounded-full bg-white">
      <HiOutlineChartPie color="gray" size={20} />
    </div>
    <div>
      <h3 className="text-sm text-gray-400">{desc}</h3>
      <h3 className="font-bold text-lg">{value}</h3>
    </div>
  </div>
);
