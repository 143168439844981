import React, { useEffect, useState } from "react";
import { TbEdit, TbTrash } from "react-icons/tb";
import { HiChevronDown, HiPlusSmall } from "react-icons/hi2";
import AddItem from "../components/AddItems";
import { deleteDoc, readDocs } from "../api";
import { useOutletContext } from "react-router-dom";
import { StoreCard } from "../components/Cards";
import _ from "lodash";
import moment from "moment";
import Header from "../components/Header";
import { Feedback } from "../components/Feedback";

export default function Items() {
  const [show, setShow] = useState(false);
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState(null);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(null);
  const [response, setResponse] = useState({open:false, message:"", type:""})

  const [showStoreList, setShowStoreList] = useState(false);

  const { country } = useOutletContext();

  useEffect(() => {
    readDocs({
      path: `/store/all/${country?.name}`,
      setData: setStores,
    });

    readDocs({
      path: `/food/all/${country?.name}`,
      setData: setItems,
    });
  }, [country]);

  const filtered = () => {
    return store === null
      ? items
      : _.filter(items, (st) => st.storeID === store._id);
  };

  const deleteFood = async (id) => {
    alert(id)
    deleteDoc({
      path:'/food', 
      id, 
      setResponse
    })
  }


  return (
    <div className="flex-1 p-2 ">
      <div className="flex justify-between items-center p-3">
        <Header
          title={"Manage Items"}
          description={
            "Get an indepth view on all items create and made available for users on the system ."
          }
        />
        <button
          onClick={() => {
            setShow(!show);
            setItem(null);
          }}
          className="p-2 bg-red-500 rounded-full text-white"
        >
          <HiPlusSmall size={20} color={"white"} />
        </button>
      </div>

      <div className="px-3 py-3">
        <p className="text-xs text-gray-500 mb-1">Selected Store</p>
        <button
          onClick={() => setShowStoreList(true)}
          className={`flex items-center text-gray-600 border p-3 rounded-xl shadow-sm gap-2`}
        >
          <img
            alt={store?.name}
            src={store?.photo}
            className="w-5 h-5 rounded-full bg-gray-500"
          />
          <p className="text-sm">{store?.name || "Select Store"} </p>

          <HiChevronDown />
        </button>
        {showStoreList && (
          <>
            <div className="absolute z-20 bg-white/70 backdrop-blur-sm h-80 w-80 border border-gray-400/50 shadow-md mt-2 rounded-md overflow-hidden ">
              <div className=" overflow-x-hidden flex p-2 pt-3 border-b border-gray-200/50">
                <input
                  type="text"
                  placeholder="Search Store ..."
                  className="border outline-none rounded-lg text-xs p-2 flex-1  bg-gray-50"
                />
              </div>
              <div className="flex gap-2  flex-col items-center">
                {stores?.map((str, idx) => (
                  <StoreCard
                    key={idx}
                    info={str}
                    store={store}
                    id={str?._id}
                    setStore={(data) => {
                      setStore(data);
                      setShowStoreList(false);
                    }}
                  />
                ))}
              </div>
            </div>
            <div
              onClick={() => setShowStoreList(false)}
              className="absolute top-0 left-0 bottom-0 right-0 z-10"
            ></div>
          </>
        )}
      </div>

      <div className="flex flex-wrap gap-2 px-3 pb-10">
        {filtered().map((item, idx) => (
          <Item deleteFood={deleteFood} key={idx} item={item} setItem={setItem} setShow={setShow} />
        ))}
      </div>


      {show && <AddItem info={item} close={() => setShow(false)} />}
      {response.open && <Feedback {...response} close={() => setResponse({open:false})}/>}
    </div>
  );
}

const Item = ({ item, setItem, setShow, deleteFood }) => (
  <div className="flex flex-col  md:w-[30%] lg:w-[24%] h-52 lg:h-72 shadow-sm  rounded-2xl overflow-hidden border cursor-pointer">
    <div
      style={{
        backgroundImage: `linear-gradient(70deg, rgba(40, 40, 50, 0.2), rgba(50, 40, 40, 0.6)),url(${item?.photo})`,
      }}
      className=" relative md:h-[50%] lg:h-2/3 bg-cover  w-full "
    >
      <button
        onClick={() => {
          setItem({ ...item, edit: true });
          setShow(true);
        }}
        className="w-10 h-10 flex items-center justify-center absolute top-2 right-2 bg-white rounded-md"
      >
        <TbEdit size={20} />
      </button>
      <button
        onClick={() => deleteFood(item._id)}
        className="w-10 h-10 flex items-center justify-center absolute top-2 right-14 bg-gray-50 rounded-md"
      >
        <TbTrash size={20} color="red"/>
      </button>
    </div>
    <div className="flex flex-col px-3 pt-2">
      <div className="flex items-center mt-2 justify-between">
        <div className="flex flex-col w-[70%] mb-2">
          <p className="text-sm font-bold text-gray-800 truncate mb-1">
            {item?.name}
          </p>
          <p className="text-xs font-medium text-gray-600">
            {item?.addons?.length} Addons
          </p>
        </div>
        <p className="text-sm font-bold">
          {item?.currencySymbol + item?.price}
        </p>
      </div>
    </div>
    <div className="flex justify-between items-center px-3">
      <p className="text-xs text-gray-500">
        Created: {moment(item.createdAt).from()}
      </p>
    </div>
  </div>
);
