import Chart from "react-apexcharts";
import moment from "moment";
import _ from "lodash";

var colorPalette = [
  "#00D8B6",
  "#008FFB",
  "#FEB019",
  "#FF4560",
  "#775DD0",
  "#FEB881",
  "#888888",
  "#A98884",
  "#BBE4C3",
];

export const options = (labels) => {
  return {
    chart: {
      type: "bar",
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    noData: {
      text: "No orders recieved today!",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
      },
    },
    stroke: {
      curve: "smooth",
    },
    colors: colorPalette,
    title: {
      text: "Incoming Orders Today",
    },
    subtitle: {
      text: "Order statistics by the hour",
      align: "left",
    },
    labels,
    legend: {
      position: "bottom",
    },
  };
};

export default function HourlyVisuals({ orders }) {
  const getSeries = () => {
    const todayOrders = _.filter(orders, (ord) =>
      moment(ord.createdAt).isSame(moment(), "day")
    );

    const grouped = _.groupBy(todayOrders, (td) =>
      new Date(td.createdAt).getHours()
    );

    const data = [],
      labels = [];
    Object.entries(grouped).forEach(([key, values]) => {
      data.push(values.length);
      labels.push(key);
    });

    return [
      [
        {
          name: "Total Orders",
          data,
        },
      ],
      labels,
    ];
  };

  const series = getSeries()[0],
    labels = getSeries()[1];

  // console.log(getSeries());

  return (
    <Chart
      type="line"
      options={options(labels)}
      width={1000}
      height={400}
      series={series}
    />
  );
}
