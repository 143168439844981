import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { readDocs } from "../api";
import _ from "lodash";
import moment from "moment";

const filters = ["All", "User", "Rider", "Refund"];
export default function Notifications({ country, close }) {
  const [filter, setFilter] = useState("All");
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    readDocs({
      path: `/alerts/all/${country?.name}`,
      setData: setAlerts,
    });
  }, [country]);

  const filtered = () => {
    return filter === "All"
      ? alerts
      : _.filter(alerts, (al) => al.tag === filter);
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={close}
      className="absolute transition-all z-10 top-0 left-0 bg-black/30 right-0 bottom-0 flex"
    >
      <motion.div
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -100, opacity: 0 }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        className="w-1/3 transition-all h-[80vh] cursor-pointer border-gray-100 overflow-hidden bg-white/70 backdrop-blur-md absolute top-20 border shadow rounded-md right-5"
      >
        <div className="flex gap-2 items-center px-5 pt-5 pb-2">
          <p className="text-lg">Notifications</p>
        </div>
        <div className="flex gap-3 w-full border-b border-gray-300 px-5 text-xs">
          {filters.map((info, idx) => (
            <button
              onClick={() => setFilter(info)}
              key={idx}
              className={`px-3 py-2 ${
                filter === info
                  ? " text-gray-700 font-medium border-b-4 border-gray-700"
                  : " text-gray-400"
              } `}
            >
              {info}
            </button>
          ))}
        </div>
        <div className="flex-1 h-[100%] overflow-y-scroll">
          <div className="flex flex-col  gap-2 p-2">
            {filtered().map((alert, idx) => (
              <Notification key={idx} alert={alert} />
            ))}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}
const Notification = ({ alert }) => {
  return (
    <div className="flex flex-col h-auto bg-white p-3 shadow-sm border border-gray-50 rounded-md relative">
      <div className="flex p-2 items-center justify-between">
        <div className="flex gap-2 items-center">
          <p className=" text-[13px] font-medium">{alert?.header}</p>
        </div>
        <p className="text-xs text-gray-400">
          {moment(alert.createdAt).from()}
        </p>
      </div>
      <p className="leading-relaxed text-[13px] pl-2 text-gray-600">
        {alert.message}
      </p>
      <div className="flex justify-end pt-3">
        <button className="hover:bg-gray-400 hover:text-white text-xs flex items-center space-x-1 rounded-full px-1 py-1 px-2">
          <p className="text-xs">Dismiss</p>
        </button>
      </div>
    </div>
  );
};
