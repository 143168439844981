import React, { useEffect, useState } from "react";
import { Navlink } from "../components/Navlink";
import { routes } from "../components/routes";
import { Outlet, useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { HiOutlineLogout, HiX } from "react-icons/hi";
import {  HiChevronRight, HiMiniCalendarDays, HiUserCircle } from "react-icons/hi2";
import { CgBell, CgSearch } from "react-icons/cg";
import Notifications from "../components/Notifications";
import { readDocs, proxy } from "../api";
import { COUNTRIES } from "../assets/defaults";
import logo from "../assets/images/logo.png";
import { Feedback } from "../components/Feedback";
import axios from "axios";
import { adminID } from "../api";
import { signOut } from "firebase/auth";
import { auth } from "../api/firebase";

export default function Layout() {
  const [show, setShow] = useState(false);
  const [countries, setCountries] = useState();
  const [toggle, setToggle] = useState(false);
  const [results, setResults] = useState([]);
  const [response, setResponse] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [toggleSearch, setToggleSearch] = useState(false)
  const navigate = useNavigate();

  const [country, setCountry] = useState(COUNTRIES[1]);

  useEffect(() => {
    readDocs({
      path: "/country",
      setData: setCountries,
    });
  }, [country]);

  const logout = () => {
    axios
      .get(`${proxy}/user/logout/${adminID}`)
      .then(() => {
        signOut(auth).then(() => {
          localStorage.clear();
          navigate("/login", { replace: true });
        });
      })
      .catch((err) =>
        setResponse({
          type: "error",
          message: err.response.data.message,
          open: true,
        })
      );
  };

  const activateSearch = async (e) => {
    if (e.target.value) {
      await readDocs({
        path: `/search/a?q=${e.target.value}`,
        setData: setResults,
      });
    } else {
      setResults([]);
    }
  };

  return (
    <div className="flex  flex-1 h-screen overflow-hidden ">
      <div className="hidden md:flex flex-col overflow-hidden bg-white border-r p-5 shrink-0 md:w-2/12 lg:w-1/5 h-screen">
        <div className="flex items-center space-x-2 mx-5 ">
          <img src={logo} alt="Eyepa" className="md:w-full   lg:w-1/2" />
        </div>
        <div className="flex flex-col flex-1 gap-2 mt-4 text-lg mx-3 ">
          {routes.map((info, idx) => (
            <Navlink to={info.to} title={info.title} key={idx}>
              <info.icon />
            </Navlink>
          ))}
        </div>
      </div>
      <div className="h-screen flex-1 flex flex-col bg-white overflow-y-auto">
        {/* Top Navigation Bar. */}
        <div className="flex sticky backdrop:shadow bg-white/50 backdrop-blur-lg z-[5] top-0 text-sm px-5 py-6 justify-between items-center">
          <div className="flex items-center text-gray-400 gap-2 md:hidden lg:flex">
            <HiMiniCalendarDays size={18} className="text-lg " />
            <p>{new Date().toDateString()}</p>
          </div>
          <div className="flex items-center space-x-3 w-[30vw] border border-gray-200 rounded-md  p-3 bg-gray-50/50">
            <button onClick={() => setToggleSearch(true)} className="py-1 flex-1 flex items-center space-x-3">
              <CgSearch size={20} color="gray" />
              <p className="text-gray-400">Search ....</p>
            </button>
          </div>
          {/* 
          {results?.length > 0 && (
            <div className="fixed top-20 p-3 w-full h-[90vh] flex justify-center bg-gray-50/80 backdrop-blur-lg flex-1">
              <div className="w-2/3 flex h-[70vh] bg-gray-200 rounded-lg">
                <div className="w-1/3 bg-blue-500 h-full flex flex-col overflow-y-auto">
                  {results?.map((data, idx) => (
                    <div className="flex flex-col" key={idx}>
                      {data.info?.map((info, idx) => (
                        <div
                          className="flex border-b   px-2 py-2 items-center gap-2 "
                          key={idx}
                        >
                          <div className="w-10 h-10 bg-black rounded-lg"></div>
                          <div className="flex flex-col ">
                            <p className="font-semibold">
                              {info?.name || info?.id}
                            </p>
                            <p className="text-xs">found in {data.desc}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                <div className="flex-1 bg-yellow-300"></div>
              </div>
            </div>
          )} */}

          <div className="flex items-center gap-3">
            <button
              className="border p-2 rounded-full"
              onClick={() => setShow(!show)}
            >
              <CgBell size={20} color="gray" />
            </button>
            <div className="w-fit gap-2 flex items-center p-1 rounded-full bg-gray-200">
              {countries?.map((info, idx) => (
                <button key={idx} onClick={() => setCountry(info)}>
                  <img
                    src={info.flag}
                    title={info.name}
                    alt={info.name}
                    className={`w-8 h-8 p-[2px] object-fit rounded-full ${country?.name === info.name
                      ? "border-blue-700 bg-white"
                      : ""
                      } border-2`}
                  />
                </button>
              ))}
            </div>

            <button onClick={() => setToggle(!toggle)}>
              <HiUserCircle size={40} color="gray" />
            </button>
            {toggle && (
              <div className="absolute top-16 right-0 h-fit p-2 rounded-b-md w-32 bg-red-500">
                <button onClick={logout} className="flex gap-2 items-center ">
                  <HiOutlineLogout size={16} color={"white"} />{" "}
                  <p className="text-sm text-white">Logout</p>
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="flex-1 flex">
          <Outlet
            context={{
              countries,
              country,
            }}
          ></Outlet>
        </div>

        <AnimatePresence>
          {toggleSearch &&
            <>
              <div className="absolute flex top-0 left-0 right-0 bottom-0 bg-black/20 z-10 backdrop:shadow  backdrop-blur-sm">

                <div className="md:w-2/12 lg:w-1/5 " />
                <div className="flex-1 flex flex-col">

                  <div className="flex relative flex-col flex-1  top-0 text-sm px-5 py-6 justify-between items-center">

                    <div className="flex items-center justify-center space-x-5">
                      <div onClick={e => {
                        e.preventDefault();
                      }} className="flex items-center space-x-3 w-[30vw] border border-gray-200 rounded-md  p-3 bg-gray-50/50">
                        <div className="py-1 flex-1 flex items-center space-x-3">
                          <CgSearch size={20} color="gray" />
                          <input onChange={activateSearch} autoFocus={toggleSearch} placeholder="Search ..." className="flex-1 bg-transparent overflow-hidden border-none outline-none focus:border-none" />
                        </div>
                      </div>
                      <button onClick={() => setToggleSearch(false)} className="h-10 w-10 rounded-full flex items-center justify-center bg-white">
                        <HiX />
                      </button>
                    </div>

                    <div className="flex space-x-5 mt-5">
                      <div className="min-h-60 max-h-[50vh] w-[30vw] rounded-md bg-white overflow-hidden overflow-y-auto">
                        {results?.length > 0 && (

                          <>
                            {results?.map((data, idx) => (
                              <div key={idx} className="px-5 pt-3 pb-2">
                                <p className="text-sm font-medium pt-3 pb-2">{data.desc} results (<span className="font-bold">{data.info.length}</span>)</p>
                                {data.info?.map((info, idx) => (

                                  <div onClick={()=>{navigate(`${data.desc.toLowerCase() === "foods" ? "items" :data.desc.toLowerCase()}?id=${info?._id}`);setToggleSearch(false)}} className="w-full border-b border-gray-100 py-3 flex items-center justify-between cursor-pointer" key={idx}>
                                    <div className="flex flex-row items-center space-x-5">
                                      <div className="w-10 h-10 bg-black/30 rounded-lg"></div>
                                      <div>
                                        <p className="font-semibold">
                                          {info?.name || info?.id}
                                        </p>
                                      </div>
                                    </div>
                                    <HiChevronRight size={18} />
                                  </div>
                                ))}
                              </div>
                            ))}
                          </>

                        )}
                      </div>
                      <div className="w-10" />
                    </div>
                    <div className="flex-1">

                    </div>

                  </div>

                </div>

              </div>

              {/* <div className="absolute top-0 left-0 right-0 bottom-0 bg-blue-950 z-10" onClick={()=>setToggleSearch(false)} /> */}

            </>}
          {show && (
            <Notifications country={country} close={() => setShow(false)} />
          )}
          {response.open && (
            <Feedback
              {...response}
              close={() => setResponse({ open: false })}
            />
          )}
        </AnimatePresence>
      </div >
    </div >
  );
}
