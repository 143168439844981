import React, { useState } from "react";
import { HiOutlineEnvelope, HiCheckCircle } from "react-icons/hi2";
import { ImSpinner10 } from "react-icons/im";
import logo from "../assets/images/logo.png";
import axios from "axios";
import { proxy } from "../api";
import { Link, useNavigate } from "react-router-dom";
import { Feedback } from "../components/Feedback";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../api/firebase";

export default function Login() {
  const [details, setDetails] = useState({ email: "", password: "" });
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate();
  const [response, setResponse] = useState({
    open: false,
    message: "",
    type: "error",
  });

  const grantAccess = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const { status, data } = await axios.post(
        `${proxy}/user/admin/login`,
        details
      );
      if (status === 200) {
        // save session and login
        signInWithEmailAndPassword(auth, details.email, details.password)
          .then(() => {
            localStorage.setItem("adminLogged", "LOGGED");
            localStorage.setItem("eyepa_id", data);
            setTimeout(() => navigate("/", { replace: true }));
          })
          .catch((err) => {
            setResponse({
              open: true,
              type: "error",
              message: err.message,
            });
          });
        setLoading(false)
        return;
      }
    } catch (error) {
      setResponse({
        open: true,
        type: "error",
        message: error.message,
      });
      setLoading(false)
    }
  };
  return (
    <div className="flex flex-1 h-screen overflow-hidden w-screen">
      <div className="md:w-2/3 w-full items-center justify-center flex flex-col space-y-5 bg-white">
        <div className="flex flex-col-reverse items-center justify-center relative">
          <div className="flex flex-col space-y-2 items-center justify-center">
            <p className="text-3xl font-bold">Welcome Back :)</p>
            <p className="text-gray-500 text-sm">Sign in to continue</p>
          </div>

          <img
            src={logo}
            alt="Eyepa Logo"
            className="w-32 h-32  object-cover rounded-full  left-50 "
          ></img>
        </div>
        <div className="flex items-center justify-center w-full md:w-1/2 ">
          <form
            onSubmit={grantAccess}
            action="#"
            className="flex flex-col  justify-center items-center gap-3 w-full   p-2"
          >
            <div className="flex flex-row items-center border py-6 px-3  pt-7 rounded-md w-full md:w-3/4 relative">
              <HiOutlineEnvelope size={20} />
              <input
                onChange={(e) =>
                  setDetails((prev) => ({ ...prev, email: e.target.value }))
                }
                id="email"
                required
                className=" ml-2 focus:border-0 focus:ring-0 placeholder:text-transparent transition focus:outline-0 flex-1 peer/email"
                type="email"
                placeholder="Admin ID"
              />

              <HiCheckCircle className="border border-white h-5 w-5 text-green-600" />

              <label
                htmlFor="email"
                className="absolute text-gray-600 left-0 ml-9 -translate-y-5 bg-white px-1 text-xs duration-100 peer-focus/email:text-xs peer-focus/email:-translate-y-5 ease-linear peer-placeholder-shown/email:translate-y-0 peer-placeholder-shown/email:text-base peer-placeholder-shown/email:text-gray-500 "
              >
                Email address
              </label>
            </div>

            <div className="flex flex-row items-center border py-6 px-3 pt-7 rounded-md w-full md:w-3/4 relative">
              <HiOutlineEnvelope size={20} />
              <input
                onChange={(e) =>
                  setDetails((prev) => ({ ...prev, password: e.target.value }))
                }
                id="password"
                className=" ml-2 focus:border-0 focus:ring-0 placeholder:text-transparent transition focus:outline-0 flex-1 peer/password"
                type="password"
                required
                placeholder="Password"
              />
              <label
                htmlFor="password"
                className="absolute text-gray-600 left-0 ml-9 -translate-y-5 bg-white px-1 text-xs duration-100 peer-focus/password:text-xs peer-focus/password:-translate-y-5 ease-linear peer-placeholder-shown/password:translate-y-0 peer-placeholder-shown/password:text-base peer-placeholder-shown/password:text-gray-500 "
              >
                Password
              </label>
            </div>

            <div className="flex justify-between w-3/4 flex-col space-y-2 items-center mt-2">
              <button
                type="submit"
                className="bg-yellow-500 w-full p-4 rounded-md font-medium text-white text-sm"
              >
                {loading ? <ImSpinner10 size={20} className="animate-spin"/> : "Sign in " }
              </button>
              <Link
                to="/forgot-password"
                className="text-sm leading-relaxed text-gray-400"
              >
                Forgotten Password?
              </Link>
            </div>
          </form>
        </div>
      </div>
      {response.open && (
        <Feedback {...response} close={() => setResponse({ open: false })} />
      )}
      <div className="flex-1 bg-welcome bg-cover"></div>
    </div>
  );
}
