import { motion } from "framer-motion";
import { TbInfoHexagonFilled, TbCircleCheckFilled } from "react-icons/tb";
import { ImSpinner2 } from "react-icons/im";
// import logo from '../../assets/images/logo.jpg'
export const Feedback = ({ type, message, close }) => {
  setTimeout(close, 5000);
  return (
    <motion.div
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      exit={{ y: 100 }}
      className={`fixed bottom-2 right-2 rounded-sm w-fit p-4 ${
        type === "success" ? "bg-green-500" : "bg-red-500"
      }`}
    >
      <div className="relative">
        <div className="flex items-center gap-2">
          {type === "success" ? (
            <TbCircleCheckFilled className="text-white font-2xl" />
          ) : (
            <TbInfoHexagonFilled className="text-white font-2xl" />
          )}
          <p className="leading-relaxed text-sm text-white">{message}</p>
        </div>
      </div>
    </motion.div>
  );
};


export const Spin = () => <ImSpinner2 size={16} className="animate-spin" /> 

/*
export const Loader = () => (
  <div className="flex h-screen backdrop-blur-md bg-gray-400/2 w-full items-center justify-center">
    <div className="flex flex-col items-center justify-center ">
        <img src={logo} alt="Eyepa Logo" className="w-28 h-28 shadow-md rounded-md animate-pulse" />
        <p className="text-sm tracking-wide text-center mt-2">Loading...</p> 
    </div>
  </div>
) */
