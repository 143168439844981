import { useEffect, useState } from "react";
import { maxDate } from "../../functions";
import {
  addDoc,
  adminID,
  deleteDoc,
  readDocs,
  socket,
  updateDoc,
} from "../../api/index";
import { auth } from "../../api/firebase";
import { Feedback } from "../../components/Feedback";
import _ from "lodash";
import { BsTrash } from "react-icons/bs";
import { TbEdit } from "react-icons/tb";

export default function Payments({ stores, country }) {
  const [records, setRecords] = useState([]);
  const [code, setCode] = useState(0);
  const [showCreate, setShowCreate] = useState(false)

  const [info, setInfo] = useState({
    storeID: "",
    amountPaid: 0.0,
    balance: 0.0,
    paymentType: "",
    datePaid: "",
  });

  const [response, setResponse] = useState({
    open: false,
    message: "",
    type: "",
  });

  socket.on(adminID, (pl) => {
    if (pl === "transaction") {
      setCode(new Date().getSeconds());
    }
  });

  useEffect(() => {
    readDocs({
      path: `/payment/get-records?code=${code}`,
      setData: setRecords,
    });
  }, [code]);

  const PAYMENT_TYPES = [
    "Weekly",
    "Monthly",
    "Yearly",
    "Commission",
    "Generic",
  ];

  const getField = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const saveInfo = async (e) => {
    e.preventDefault();
    const name = _.find(stores, (str) => str._id === info.storeID)?.name;
    if (info?.edit) {
      await updateDoc({
        path: "/payment/record",
        data: info,
        id: info._id,
        setResponse,
      });

      setInfo({ edit: false });
    } else {
      await addDoc({
        path: "/payment/add-record",
        data: {
          ...info,
          store: name,
          signature: auth.currentUser.email,
          paymentID: new Date().getTime(),
        },
        setResponse,
      });
    }
  };
  return (
    <div className="flex w-full gap-2">

      <div className="flex-1 p-2 ">
        <div className="w-full flex items-center justify-between py-5 px-5">
          <p className="font-medium text-lg font-semibold">Manage Payment.</p>
          <button onClick={() =>setShowCreate(true)} className="bg-yellow-500 px-5 py-2 rounded text-sm text-white font-semibold">
            Make Payment
          </button>
        </div>

        <div className="-my-2 overflow-x-auto px-5">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Store
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Date
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {records.map((info, idx) => (
                    <tr key={info.email}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">

                          <div className="">
                            <div className="text-sm font-medium text-gray-900">{info.store}</div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900"> {country?.currencySymbol + info?.amountPaid}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {info?.paymentType}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{info?.datePaid}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="flex w-full items-center gap-2">
                          <button
                            onClick={() =>
                              deleteDoc({
                                path: "/payment/record",
                                id: info._id,
                                setResponse,
                              })
                            }
                          >
                            <BsTrash size={18} color="red" />
                          </button>
                          <button onClick={() => setInfo({ ...info, edit: true })}>
                            <TbEdit size={18} color="green" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {showCreate && <div onClick={() =>setShowCreate(false)} className="absolute top-0 left-0 bg-black/50 backdrop-blur-sm flex right-0 bottom-0 z-10 cursor-pointer">
        <div className="flex-1  relative">

          <form
            action="#"
            onSubmit={saveInfo}
            onClick={(e) =>{
              e.stopPropagation();
            }}
            className="flex flex-col mt-5 gap-2 w-2/6 mx-auto bg-white rounded-md p-5"
          >
            <div className="flex flex-col items-center mb-5 mt-2">
              <p className="text-lg font-extrabold">Create Payment</p>
              <p className="text-sm text-gray-500 leading-relaxed">Fill the details below to create a new payment.</p>
            </div>
            <div className="flex flex-row items-center w-full space-x-2">

            <select
              onChange={getField}
              value={info.storeID}
              name="storeID"
              id="storeID"
              className="p-3 border outline-none rounded-md flex-1"
            >
              <option value="">Select Store</option>
              {stores?.map((str, idx) => (
                <option key={idx} value={str._id}>
                  {str.name}
                </option>
              ))}
            </select>

            <select
              value={info?.paymentType}
              name="paymentType"
              id="paymentType"
              onChange={getField}
              className="p-3 border outline-none rounded-md flex-1"
            >
              <option value="">Payment Type</option>
              {PAYMENT_TYPES.map((info) => (
                <option key={info} value={info}>
                  {info}
                </option>
              ))}
            </select>
            </div>

            <div className="flex items-center space-x-2">
            <div className="flex flex-col flex-1">
              <label htmlFor="amountPaid" className="text-sm">
                Amount
              </label>
              <input
                onChange={getField}
                value={info?.amountPaid}
                type="number"
                step={0.001}
                min="0"
                name="amountPaid"
                id="amount"
                className="p-3 border outline-none rounded-md"
              />
            </div>

            <div className="flex flex-col flex-1">
              <label htmlFor="balance" className="text-sm">
                Balance
              </label>
              <input
                onChange={getField}
                value={info?.balance}
                type="number"
                step={0.001}
                min="0"
                name="balance"
                id="balance"
                className="p-3 border outline-none rounded-md"
              />
            </div>
            </div>

            <div className="flex flex-col">
              <label htmlFor="datePaid" className="text-sm">
                Date
              </label>
              <input
                type="date"
                value={info?.datePaid}
                min={maxDate()}
                onChange={getField}
                name="datePaid"
                id="datePaid"
                className="p-3 border outline-none rounded-md"
              />
            </div>

            <button
              type="submit"
              className={`p-3 mt-8 border outline-none text-white ${info?.edit ? "bg-green-600" : "bg-yellow-500"
                } rounded-md`}
            >
              {info?.edit ? "Edit Record" : "Save Record"}
            </button>
          </form>
          {response.open && (
            <Feedback {...response} close={() => setResponse({ open: false })} />
          )}
        </div>
      </div>}

    </div>
  );
}
