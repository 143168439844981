import React, { useEffect, useState } from "react";
import { addDoc, readDocs, updateDoc } from "../../api";
import { getAllInfoByISO } from "iso-country-currency";
import { Feedback } from "../../components/Feedback";
import { countryListAllIsoData } from "../../assets/flags";
import { HiGlobeAlt } from "react-icons/hi";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const roles = ["General", "Another 1", "Test 3", "Last Role"];

export default function Reps() {
  const [data, setData] = useState({
    country: { name: "", code: "" },
  });
  const [phone, setPhone] = useState();
  const [countries, setCountries] = useState([]);
  const [search, setSearch] = useState("");
  const [showCreate, setShowCreate] = useState(false);
  const [showCountryList, setShowCountryList] = useState(false);

  const [response, setResponse] = useState({
    open: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    readDocs({
      path: "/country",
      setData: setCountries,
    });
  }, []);

  const setField = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const addCountry = (e) => {
    e.preventDefault();
    // eslint-disable-next-line

    const country = data.country;
    let { currency, symbol, countryName } = getAllInfoByISO(country?.code);
    const info = {
      ...data,
      name: countryName,
      currencySymbol: symbol,
      currency,
      phone,
      flag: `https://flagcdn.com/48x36/${country?.code?.toLowerCase()}.png`,
    };

    if (data?.edit) {
      updateDoc({
        path: "/country",
        id: data._id,
        data: info,
        setResponse,
      });

      setData({ edit: false });
    } else {
      addDoc({
        path: "/country",
        data: info,
        setResponse,
      });
    }
  };
  return (
    <div className="flex w-full">
      <div className="flex-1 p-5">
        <div className="w-full flex items-center justify-between py-5">
          <p className="text-lg font-semibold">Manage Reps.</p>
          <button
            onClick={() => setShowCreate(true)}
            className="bg-yellow-500 px-5 py-2 rounded text-sm text-white font-semibold"
          >
            Create Representative
          </button>
        </div>

        <div className="py-2 align-middle inline-block min-w-full">
          <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Country
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Representative
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Phone
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Currency
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {countries?.map((info, idx) => (
                  <tr
                    key={idx}
                    onClick={() => {
                    setShowCreate(true);
                      setData({ ...info, edit: true });
                      setPhone(info.phone)
                    }}
                    className="cursor-pointer"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center space-x-3">
                        <img
                          src={info?.flag}
                          alt="Flag"
                          className="w-5 h-5 object-contain"
                        />
                        <div className="">
                          <div className="text-sm font-medium text-gray-900">
                            {info?.name}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {info?.repName}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {info?.phone}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {" "}
                      {info?.currency} ({info?.currencySymbol})
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {showCreate && (
        <div
          onClick={() => setShowCreate(false)}
          className="bg-black/50 backdrop-blur-sm top-0 left-0 right-0 bottom-0 absolute z-10"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-1/3 mx-auto p-5 bg-gray-50 rounded-md mt-10"
          >
            <form
              action="#"
              onSubmit={addCountry}
              className="p-3 w-full flex gap-2 flex-col"
            >
              <h1 className="text-lg">Add or Modify Country</h1>
              <div className="w-full flex flex-col space-y-2">
                <div className="flex flex-row items-center border py-6 px-3 pt-7 rounded-md w-full relative">
                  {!data.country?.name ? (
                    <HiGlobeAlt size={20} />
                  ) : (
                    <img
                      className="h-5"
                      src={`https://flagcdn.com/48x36/${data.country?.code?.toLowerCase()}.png`}
                      alt="country_flag"
                    />
                  )}
                  <input
                    id="country"
                    className=" ml-2 focus:border-0 focus:ring-0 placeholder:text-transparent transition focus:outline-0 flex-1 peer/country"
                    type="text"
                    placeholder="Country"
                    name="country"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onFocus={(e) => setShowCountryList(true)}
                  />

                  <label
                    htmlFor="country"
                    className="absolute text-gray-600 left-0 ml-9 -translate-y-5 bg-white px-1 text-xs duration-100 peer-focus/country:text-xs peer-focus/country:-translate-y-5 ease-linear peer-placeholder-shown/country:translate-y-0 peer-placeholder-shown/country:text-base peer-placeholder-shown/country:text-gray-500 "
                  >
                    {data.country?.name || "Select Country"}
                  </label>
                </div>

                {showCountryList && (
                  <div className="bg-white max-h-48 border rounded-md overflow-hidden p-3 overflow-y-auto space-y-4">
                    {countryListAllIsoData
                      .filter(({ name }, index) =>
                        name?.toLowerCase().startsWith(search.toLowerCase())
                      )
                      .map((data, index) => (
                        <div
                          onClick={(e) => {
                            setData((prev) => ({ ...prev, country: data }));
                            setSearch(data.name);
                            setShowCountryList(false);
                          }}
                          key={index}
                          className="flex items-center space-x-5 hover:bg-gray-200 hover:p-2 transition-all rounded"
                        >
                          <img
                            className="h-5"
                            src={`https://flagcdn.com/48x36/${data.code?.toLowerCase()}.png`}
                            alt="Country"
                          />
                          <p>{data.name}</p>
                        </div>
                      ))}
                  </div>
                )}
              </div>

              <div className="flex flex-col">
                <label htmlFor="repName" className="text-sm mb-1">
                  Rep's Name
                </label>
                <input
                  type="text"
                  placeholder="Name"
                  value={data.repName}
                  required
                  className="p-2 rounded-sm outline-none bg-gray-100 border"
                  onChange={setField}
                  name="repName"
                  id="repName"
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="repPhone" className="text-sm mb-1">
                  Rep's Phone
                </label>
                <PhoneInput
                  placeholder="Enter Phone Number"
                  className="p-2 bg-gray-50 outline-none"
                  value={phone}
                  onChange={setPhone}
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="repID" className="text-sm mb-1">
                  Rep's Email
                </label>
                <input
                  type="email"
                  placeholder="Email"
                  onChange={setField}
                  required
                  value={data.repID}
                  className="p-2 rounded-sm outline-none bg-gray-100 border"
                  name="repID"
                  id="repID"
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="password" className="text-sm mb-1">
                  Password
                </label>
                <input
                  type="password"
                  placeholder="Password"
                  onChange={setField}
                  value={data?.edit ? " " : data.password}
                  className="p-2 rounded-sm outline-none bg-gray-100 border"
                  name="password"
                  id="password"
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="role" className="text-sm mb-1">
                  Role
                </label>
                <select
                  className="p-2 border outline-none bg-gray-100"
                  name="role"
                  id="role"
                  onChange={setField}
                >
                  <option value="">-- Role --</option>
                  {roles.map((info) => (
                    <option value={info} key={info}>
                      {info}
                    </option>
                  ))}
                </select>
              </div>

              <button
                type="submit"
                className={`${
                  data?.edit
                    ? "bg-green-700 text-white"
                    : "bg-gray-800 text-white"
                } rounded-md text-sm px-3 py-2 w-fit mt-2`}
              >
                {data?.edit ? "Edit Details" : "Add Details"}
              </button>
            </form>
            {response.open && (
              <Feedback
                {...response}
                close={() => setResponse({ open: false })}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
