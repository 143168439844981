import React, { useState, useEffect } from "react";
import { HiLocationMarker } from "react-icons/hi";
import {
  HiArrowLeft,
  HiClock,
  HiMiniCurrencyDollar,
  HiPhone,
  HiTruck,
} from "react-icons/hi2";
import { motion } from "framer-motion";
import moment from "moment";
import { proxy, updateDoc, readDocs } from "../api";
import { Feedback } from "./Feedback";
import { useOutletContext } from "react-router-dom";

const imageProxy = proxy + "/assets/images/";

export default function OrderInfoModal({ close, order }) {
  const [response, setResponse] = useState({
    open: false,
    message: "",
    type: "error",
  });

  const { country } = useOutletContext();
  const [rider, setRider] = useState(null);
  const [deliveryPin, setDeliveryPin] = useState("");

  useEffect(() => {
    if (order?.riderID !== null) {
      readDocs({
        path: `/rider/${order?.riderID}`,
        setData: setRider,
      });
    }
  }, [order?.riderID]);

  const completeOrder = () => {
    updateDoc({
      path: "/order/complete/",
      id: `${order._id}/${deliveryPin}`,
      data: {},
      setResponse,
    });
  };

  const notifyRider  = () => {
    updateDoc({
      path:'/rider/confirm-pickup/:riderID/:orderID', 
      id:`${rider?._id}/${order?._id}`, 
      data:{}, 
      setResponse
    })
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0 }}
      className="absolute bg-black/20 backdrop-blur-sm flex items-center justify-center overflow-hidden top-0 left-0 right-0 bottom-0 z-20"
    >
      <motion.div
        initial={{ y: "100vh" }}
        animate={{ y: 0 }}
        transition={{ duration: 0.4 }}
        exit={{ y: "100vh", opacity: 0 }}
        className="bg-white flex flex-col h-[95vh] overflow-hidden overflow-y-auto w-full md:w-2/4 rounded-xl gap-5  pb-10"
      >
        <div className="top-0 sticky bg-white/30 backdrop-blur-md p-10 flex flex-row items-center gap-5">
          <button
            onClick={close}
            className="bg-gray-100 hover:bg-gray-50 transition-all delay-100 h-10 w-10 rounded-full flex items-center justify-center"
          >
            <HiArrowLeft />
          </button>
          <h4 className="font-semibold">ORDER #{order?.id} ({order?.orderType}) </h4>
        </div>

        {/* <div className="flex flex-row items-center  mx-10 gap-3">
            <HiCheckCircle size={20} className="text-green-700" />
            <p className="text-xl font-semibold  text-green-600">Purchased</p>
          </div> */}

        <div className="mx-10 flex flex-col gap-5">
          {order.items?.map((info, idx) => (
            <div className="flex flex-col gap-3" key={idx}>
              <div className="flex flex-row items-center gap-3">
                <div className="bg-gray-100 h-14 w-14 rounded-xl overflow-hidden">
                  <img
                    className="h-full w-full"
                    alt="order_image"
                    src={imageProxy + info.photo}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-lg font-semibold text-gray-900">
                    {info?.name}
                  </p>
                  <p className="text-xs text-gray-700">#{order?.id}</p>
                </div>
              </div>

              <div className="ml-2 flex flex-col gap-5">
                <div>
                  <p className="text-sm text-gray-900">Purchased Addons</p>
                  <p className="text-sm mt-1 text-gray-500 font-light">
                    List of all the extra purchased by the user including the
                    quantity.
                  </p>
                </div>
                <div className=" flex flex-col gap-5">
                  <div className="flex flex-row items-center gap-3">
                    {info.addons?.length > 0 && (
                      <table className="table-auto w-full">
                        <thead className="bg-gray-50">
                          <tr className="border-y">
                            <th className="text-sm p-3 text-left">Addon</th>
                            <th className="text-sm p-3 text-left">Quantity</th>
                            <th className="text-sm p-3 text-left">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {info.addons?.map((ad, idx) => (
                            <tr key={idx} className="border-b">
                              <td className="p-3">{ad.description}</td>
                              <td className="p-3">{ad?.quantity}</td>
                              <td className="p-3">
                                {country?.currencySymbol}
                                {ad.price}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className=" pb-10 mx-10 pt-5">
          <div className="flex items-center gap-5">
            <button className="bg-gray-100 hover:bg-gray-50 transition-all delay-100 h-10 w-10 rounded-full flex items-center justify-center">
              <HiMiniCurrencyDollar />
            </button>
            <div className=" py-2 rounded-2xl">
              <p className="text-xs text-gray-700 ">Total Price</p>
              <p className="font-bold">
                {country?.currencySymbol + Number(order?.storePrice).toFixed(2)}
              </p>
            </div>
            <div className="flex items-center px-10">
              <div className="h-2 w-2 rounded-full bg-gray-400" />
            </div>
            <button className="bg-gray-100 hover:bg-gray-50 transition-all delay-100 h-10 w-10 rounded-full flex items-center justify-center">
              <HiClock />
            </button>
            <div className=" py-2 rounded-2xl">
              <p className="text-xs text-gray-700 ">Created at</p>
              <p className="font-bold">{moment(order?.createdAt)?.from()}</p>
            </div>
          </div>
        </div>

        {/* if order is open */}
        <div className="flex-1 flex flex-col  mx-10">
          <div className="flex-1"></div>
          <div className="flex flex-row gap-2 items-center">
            <button
              disabled={["Cancelled", "Completed", "Ready"].includes(
                order.status
              )}
              onClick={() =>
                updateDoc({
                  path: "/order",
                  id: order._id,
                  data: { status: "Ready" },
                  setResponse,
                })
              }
              className="flex-1 rounded-xl px-2 py-5 w-fit bg-green-500 text-sm text-white "
            >
              Make Ready
            </button>
            <button
              disabled={["Completed", "Preparing", "Ready", "Ongoing", "Refund"].includes(
                order.status
              )}
              onClick={() => {
                updateDoc({
                  path: "/order",
                  id: order._id,
                  data: { status: "Cancelled" },
                  setResponse,
                });
              }}
              className="flex-1 rounded-xl px-2 py-5 w-fit text-white text-sm bg-red-500"
            >
              Refund
            </button>
          </div>
        </div>
        {/* end if order is open */}

        {/* if order is accepted */}
        <hr />

        {order?.orderType === "Delivery" && (
          <div className="mx-10 flex flex-col gap-3">
            <p className="text-sm">Dispatch Mode</p>
            <div className="flex flex-row items-center gap-3">
              <div className="bg-gray-100 hover:bg-gray-50 transition-all delay-100 h-10 w-10 rounded-full flex items-center justify-center">
                <HiTruck />
              </div>
              <p className="text-base text-gray-700">Delivery</p>
            </div>

            <div className="flex flex-row items-start w-1/2 gap-3">
              <div className="bg-gray-100 shrink-0 hover:bg-gray-50 transition-all delay-100 h-10 w-10 rounded-full flex items-center justify-center">
                <HiLocationMarker />
              </div>
              <div>
                <p className="text-xs text-gray-700 mb-1 font-light">
                  Delivery address
                </p>
                <p>{order?.deliveryTo?.location}</p>
                <p>{order?.deliveryTo?.address}</p>
              </div>
            </div>

            {rider && (
              <div className="flex flex-row items-center justify-between w-full">
                <div className="flex flex-row items-start gap-3 w-1/2">
                  <div className="bg-gray-100 shrink-0 hover:bg-gray-50 transition-all delay-100 h-10 w-10 rounded-full flex items-center justify-center">
                    <img
                      className="w-full h-full rounded-full"
                      alt="rider_image"
                      src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=2960&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    />
                  </div>
                  <div>
                    <p className="text-xs text-gray-700  font-light">
                      Dispatch Rider
                    </p>
                    <p>{rider?.name}</p>
                  </div>
                </div>
                <div className="w-1/2 p-2 flex items-center flex-row border-l pl-5">
                  <div className="flex-1">
                    <p className="text-xs text-gray-700  font-light">
                      Dispatch ID
                    </p>
                    <p>{rider?.email}</p>
                  </div>
                  <a
                    href={`tel:${rider?.phone}`}
                    className="bg-gray-100 shrink-0 cursor-pointer hover:bg-gray-50 transition-all delay-100 h-10 w-10 rounded-full flex items-center justify-center"
                  >
                    <HiPhone />
                  </a>
                </div>
              </div>
            )}

            {["Completed", "Delivered"].includes(order?.status) && (
              <div className="flex items-center gap-5">
                <div className="bg-green-500 px-3 py-2 rounded-full">
                  <p className="text-xs text-white">Delivered</p>
                </div>
                <div className="flex items-center gap-2">
                  <div className="h-2 w-2 rounded-full bg-gray-400" />
                  <p className="text-xs">{moment(order?.deliveredAt).from()}</p>
                </div>
              </div>
            )}

            {(order.orderType === "Delivery" && order?.riderStatus !== "Assigned" && order.status === "Preparing") && (
              <div className="flex-1 flex flex-col  mt-10">
                <div className="flex-1"></div>
                <div className="flex flex-row gap-2 items-center">
                  <button disabled={order?.status !== "Preparing"} onClick={notifyRider} className="flex-1 rounded-xl px-2 py-5 w-fit bg-green-500 text-sm text-white ">
                    Ready for pick up
                  </button>
                  <button disabled className="flex-1 rounded-xl px-2 py-5 w-fit text-black text-sm bg-gray-200">
                    Issue
                  </button>
                </div>
              </div>
            )}

            {order.orderType === "Pickup" && (
              <div className="flex items-center w-full">
                <input
                  onChange={(e) => setDeliveryPin(parseInt(e.target.value))}
                  type="number"
                  min={1000}
                  placeholder="Enter Delivery PIN"
                  className="p-3 border outline-none w-3/4"
                />
                <button
                  onClick={completeOrder}
                  className="p-3 bg-green-500 rounded-r-md text-white"
                >
                  Complete Order
                </button>
              </div>
            )}
          </div>
        )}
      </motion.div>
      {response.open && (
        <Feedback {...response} close={() => setResponse({ open: false })} />
      )}
      {/* end if order is accepted */}
    </motion.div>
  );
}
