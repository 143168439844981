import Chart from "react-apexcharts";
import _ from 'lodash'

export default function Demographics({ users }) {
  const getSeries = () => {
    const grouped = _.groupBy(users, (usr) => usr.location?.city.trim());
    const labels = [],
      series = [];
    Object.entries(grouped).forEach(([key, values]) => {
      labels.push(key);
      series.push(values.length);
    });

    return [labels, series ]
  };

  const info = getSeries()

  const options = () => {
    return {
      chart: {
        width: 380,
        type: "donut",
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
            },
          },
        },
      },
      labels: info[0],
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        floating: false,
        fontSize: "14px",
        fontFamily: "Helvetica, Arial",
        fontWeight: 400,
      },
      noData: {
        text: undefined,
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "14px",
          fontFamily: undefined,
        },
      },
    };
  };
  return <Chart type="donut" options={options()} series={info[1]} width={350} />;
}
