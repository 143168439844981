
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage  } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAFm5x34S0rRozfHkfSy0zLRNpighfLI-A",
  authDomain: "eyepa-mobile.firebaseapp.com",
  databaseURL: "https://eyepa-mobile-default-rtdb.firebaseio.com",
  projectId: "eyepa-mobile",
  storageBucket: "eyepa-mobile.appspot.com",
  messagingSenderId: "275986730217",
  appId: "1:275986730217:web:84fe31d024752a4c007478",
  measurementId: "G-TSMVCRZPYY"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app)
export const auth = getAuth(app)
