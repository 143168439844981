import axios from "axios";
import { io } from "socket.io-client";
import {
  getDownloadURL,
  uploadBytes,
  ref,
} from "firebase/storage";
import { storage } from "./firebase";

export const proxy =
  process.env.NODE_ENV === "production"
    ? "https://api.eyepadelivery.com"
    : "http://localhost:4000";

export const imageProxy = proxy + "/assets/images/";
export const socket = io(proxy);
export const tempImage = "https://cdn-icons-png.freepik.com/512/6813/6813762.png"

export const storeID = localStorage.getItem("storeID");
export const adminID = localStorage.getItem("eyepa_id");

export const readDocs = async ({ path, setData }) => {
  try {
    const { data } = await axios.get(`${proxy + path}`, {
      headers: { userID: adminID },
    });
    setData(data);
  } catch (err) {
    setData([]);
  }
};

export const deleteDoc = async ({ path, id, setResponse }) => {
  try {
    const { data, status } = await axios.delete(`${proxy + path}/${id}`, {
      headers: { userID: adminID },
    });

    setResponse({
      open: true,
      type: status === 200 ? "success" : "error",
      message: data,
    });
  } catch (err) {
    const { message } = err;
    setResponse({
      open: true,
      type: "error",
      message: message,
    });
  }
};

export const updateDoc = async ({ path, id, data, setResponse }) => {
  try {
    const info = await axios.patch(`${proxy + path}/${id}`, data, {
      headers: { userID: adminID },
    });
    setResponse({
      open: true,
      message: info.data,
      type: info.status === 200 ? "success" : "error",
    });
  } catch (err) {
    console.log(err);
    const { message } = err;
    setResponse({
      message,
      open: true,
      type: "error",
    });
  }
};

export const addDoc = async ({ path, data, setResponse }) => {
  try {
    const info = await axios.post(`${proxy + path}/`, data, {
      headers: { userID: adminID },
    });

    setResponse({
      open: true,
      message: info.data,
      type: info.status === 200 ? "success" : "error",
    });

  } catch (err) {
    

    setResponse({
      message: err.message,
      type: "error",
      open: true,
    });
  }
};

export const uploadFile = async ({ folderName, file, setData }) => {
  const fileRef = ref(storage, folderName + "-eyepa");
  await uploadBytes(fileRef, file).then(() => {
    getDownloadURL(fileRef).then((url) => setData(url));
  });
};

/*export const uploadFile = async ({
  file,
  folderName,
  setData,
  setResponse,
}) => {
  try {
    const { data } = await axios.post(
      `${proxy}/uploads`,
      {
        photo: file,
      },
      { headers: { folderName, "Content-Type": "multipart/form-data" } }
    );

    setData(data);
  } catch (error) {
    setResponse(error.message);
  }
};*/
