import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Feedback } from "../../components/Feedback";
import { LuStar } from "react-icons/lu";
import { TbTrash, TbEdit } from "react-icons/tb";
import { socket, addDoc, readDocs, updateDoc, deleteDoc } from "../../api";

export default function Items({ storeID }) {
  const [category, setCategory] = useState("");
  const [edit, setEdit] = useState({ edit: false, id: "" });
  const [categories, setCategories] = useState([]);
  const [call, setCall] = useState(0);

  const [response, setResponse] = useState({
    open: false,
    message: "",
    type: "",
  });

  const editData = (id, category) => {
    setEdit({
      edit: true,
      id,
    });
    setCategory(category);
  };

  useEffect(() => {
    socket.on("category-added", (id) => {
      if (id === storeID) {
        setCall(new Date().getSeconds());
      }
    });

    for (var soc of ["category-deleted", "category-patched"]) {
      socket.on(soc, () => setCall(new Date()));
    }

    readDocs({
      path: `/category/store/${storeID}?call=${call}`,
      setData: setCategories,
    });

    // return socket.off("item-added");
  }, [call, storeID]);

  const addCategory = async (e) => {
    e.preventDefault();
    if (edit?.edit === false) {
      await addDoc({
        path: "/category",
        data: {
          name: category,
          storeID,
        },
        setResponse,
      });

      setCategory("");
      return;
    }

    await updateDoc({
      path: "/category",
      id: edit?.id,
      data: {
        name: category,
        storeID,
      },
      setResponse,
    });
    setEdit({
      edit: false,
      id: "",
    });

    setCategory("");
  };

  return (
    <motion.div
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      exit={{ y: 100 }}
      className="bg-white p-5 bottom-0 md:right-0 flex flex-col gap-7 flex-1 h-[70vh] md:h-[80vh] w-full"
    >
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-2  pb-2">
          <h3 className="text-2xl font-bold">Category</h3>
          <p className="text-sm text-gray-500">
            Add and View list of Categories associated to your menus
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-3">
        <form
          onSubmit={addCategory}
          className="flex flex-row items-center gap-3"
        >
          <div className="flex flex-col gap-2 flex-1">
            <label className="text-sm text-gray-600" htmlFor="category">
              Food Type
            </label>
            <input
              className="placeholder:text-sm border outline-none p-3"
              type="text"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              placeholder="Item Category"
            />
          </div>
          <button
            type="submit"
            className={`w-fit p-2 text-sm ${
              edit.edit ? "bg-green-500" : "bg-gray-500"
            } text-white mt-6 px-6  rounded-md`}
          >
            {edit?.edit ? "Update Category" : "Add Category"}
          </button>
        </form>

        <div className="flex flex-col">
          <h3 className="py-4 font-bold">Added Categories</h3>
          <div className="flex flex-col gap-2">
            {categories?.map((info, idx) => (
              <Category
                info={info}
                key={idx}
                editData={editData}
                separator={true}
                setResponse={setResponse}
              />
            ))}
          </div>
        </div>
      </div>
      {response.open && (
        <Feedback {...response} close={() => setResponse({ open: false })} />
      )}
    </motion.div>
  );
}

export const Category = ({ info, setResponse, separator, editData }) => (
  <div
    className={`flex items-center ${
      separator ? "border-b border-gray-100" : ""
    } pb-5 justify-between w-full py-2`}
  >
    <div className="flex items-center gap-2">
      <LuStar className="text-green text-lg" />
      <p className="text-md font-medium text-gray-600">{info.name}</p>
    </div>
    <div className="flex items-center gap-5">
      <button onClick={() => editData(info._id, info.name)}>
        <TbEdit size={18} className="text-gray-700" />
      </button>

      <button
        onClick={() =>
          deleteDoc({
            path: `/category/`,
            id: info._id,
            setResponse,
          })
        }
      >
        <TbTrash size={18} className="text-red-400" />
      </button>
    </div>
  </div>
);
