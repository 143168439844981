import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { HiOutlineCloudUpload, HiLocationMarker } from "react-icons/hi";
import { IoIosCloseCircle } from "react-icons/io";
import { DEFAULT_STORE } from "../assets/defaults";
import { uploadFile, addDoc, updateDoc } from "../api";
import GoogleMapReact from "google-map-react";
import CompletePlaces, {
  getLatLng,
  geocodeByAddress,
} from "react-places-autocomplete";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Feedback } from "../components/Feedback";
import { useOutletContext } from "react-router-dom";
import Header from "../components/Header";
import { HiArrowLeft } from "react-icons/hi2";

export default function AddStore({ close, company }) {
  const { country } = useOutletContext();
  const [place, setPlace] = useState("");
  const [locationModal, setShowLocationModal] = useState(false);
  const [phone, setPhone] = useState();
  const [store, setStore] = useState({
    ...DEFAULT_STORE,
    country: country.name,
    deliveryRadius: 10,
    currencySymbol: country.currencySymbol,
  });

  useEffect(() => {
    if (company !== null) {
      setStore({
        ...company,
        country: country.name,
        currencySymbol: country.currencySymbol,
        edit: true,
      });
      setPhone(company?.phone)
    }
  }, [company, country]);
  const [response, setResponse] = useState({
    open: false,
    type: "",
    message: "",
  });

  const getField = (e) => {
    setStore((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const addStore = async (e) => {
    e.preventDefault();

    if (store?.edit) {
      updateDoc({
        path: "/store",
        id: company._id,
        data: {...store, phone},
        setResponse,
      }).then(() => setStore({ ...DEFAULT_STORE }));
      return;
    }

    addDoc({
      path: "/store",
      data: {...store, phone},
      setResponse,
    }).then(() => setStore((prev) => ({ ...prev, ...DEFAULT_STORE })));
  };

  const setAddress = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((cord) => {
        let len = address.split(",").length;
        setStore({
          ...store,
          coordinates: [cord.lat, cord.lng],
          fullAddress: address,
          city: address.split(",")[len - 2] || "N/A",
        });
      })
      .catch((error) => console.error("Error", error));
    setPlace(address);
  };

  // console.log(place)
  return (
    <motion.div
      initial={{ x: 100 }}
      animate={{ x: 0 }}
      exit={{ x: 100 }}
      className="flex fixed top-0 z-50 right-0 w-full h-screen"
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        onClick={close}
        className="flex flex-col bg-gray-500/20 backdrop-blur-sm md:1/12 lg:w-2/4"
      ></motion.div>
      <div className="flex-1 overflow-y-auto bg-white p-10 flex flex-col space-y-5">
        <button
          className="h-10 w-10 flex items-center justify-center rounded-full bg-gray-100 border border-gray-100 shrink-0"
          onClick={close}
        >
          <HiArrowLeft size={18} />
        </button>
        <div className="pb-5 flex items-center justify-between">
          <Header
            title={store?.edit ? "Edit Store" : "Add New Store"}
            description={
              "Lets start by providing some information about the new restaurant"
            }
          />
          <img
            src={store?.photo}
            alt={store?.name}
            className="h-28 w-28 rounded-full object-cover"
          />
        </div>

        <form
          encType="multipart/form-data"
          action="#"
          onSubmit={addStore}
          className="flex flex-col space-y-8"
        >
          <div className="flex flex-col space-y-2">
            <label htmlFor="name" className="text-sm text-gray-500">
              Name
            </label>
            <input
              onChange={getField}
              required
              type="text"
              name="name"
              id="name"
              placeholder="Restaurant Name"
              className="p-4 outline-none border bg-gray-50 rounded-md"
              value={store.name}
            />
          </div>
          <div className="flex w-full space-x-2">
            <div className="flex w-full flex-col space-y-2">
              <label htmlFor="email" className="text-sm text-gray-500">
                Email
              </label>
              <input
                placeholder="Email"
                onChange={getField}
                required
                className="p-2 outline-none border bg-gray-50 rounded-md"
                type="email"
                name="email"
                id="email"
                value={store.email}
              />
            </div>
            <div className="flex flex-col w-full space-y-2">
              <label htmlFor="phone" className="text-sm text-gray-500">
                Phone
              </label>
              <PhoneInput value={phone} onChange={setPhone} />
            </div>
          </div>
          {/* Operational Area. */}
          <div className="flex flex-col gap-5">
            <button
              type="button"
              onClick={() => setShowLocationModal(true)}
              className=" flex items-center text-xs text-blue-800 gap-2 font-medium"
            >
              <HiLocationMarker />
              <p>Select Operational Area</p>
            </button>
            <div className="bg-gray-100 w-full h-40 rounded-md">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyBam0t4ehS4qwHsXih3OgkCCRtNjGfNHdw",
                  libraries: ["places"],
                }}
                onChange={(value) => {}}
                defaultCenter={{
                  lat: store?.latitude,
                  lng: store?.longitude,
                }}
                center={{
                  lat: store?.coordinates[0],
                  lng: store?.coordinates[1],
                }}
                yesIWantToUseGoogleMapApiInternals
                defaultZoom={11}
              >
                <HiLocationMarker size={30} />
              </GoogleMapReact>
            </div>
          </div>

          <div className="border p-4 flex items-start gap-3">
            <HiLocationMarker />
            <p className="w-2/3 md:w-2/5 text-xs text-gray-500">
              {store?.fullAddress}
            </p>
          </div>

          {/* Location Stuff */}
          <div className="flex flex-col space-y-8">
            <div className="flex gap-2 w-full">
              <div className="w-1/3 flex flex-col space-y-2">
                <label htmlFor="fullAddress" className="text-sm text-gray-500">
                  Address
                </label>

                <input
                  type="text"
                  name="fullAddress"
                  placeholder="Full Address"
                  id="fullAddress"
                  className="p-4 flex-1 outline-none border bg-gray-50 rounded-md"
                  value={store.fullAddress}
                  onChange={getField}
                />
              </div>
              <div className="w-1/3 flex flex-col space-y-2">
                <label htmlFor="city" className="text-sm text-gray-500">
                  City
                </label>
                <input
                  type="text"
                  placeholder="City"
                  name="city"
                  id="city"
                  value={store.city}
                  className="p-4 flex-1 outline-none border bg-gray-50 rounded-md"
                  onChange={getField}
                />
              </div>
              <div className="w-1/3 flex flex-col space-y-2">
                <label htmlFor="landmark" className="text-sm text-gray-500">
                  Landmark
                </label>
                <input
                  type="text"
                  placeholder="Land Mark"
                  name="landMark"
                  className="p-4 flex-1 outline-none border bg-gray-50 rounded-md"
                  id="landMark"
                  value={store.landMark}
                  onChange={getField}
                />
              </div>
            </div>

            <div className="flex gap-2 w-full">
              <div className="flex-1 flex flex-col space-y-2">
                <label htmlFor="lat" className="text-sm text-gray-500">
                  Latitude
                </label>
                <input
                  type="number"
                  name="latitude"
                  placeholder="Latitude"
                  id="lat"
                  className="p-4 flex-1 outline-none border bg-gray-50 rounded-md"
                  value={store?.coordinates[0]}
                  step={0.001}
                  onChange={getField}
                />
              </div>
              <div className="flex-1 flex flex-col space-y-2">
                <label htmlFor="long" className="text-sm text-gray-500">
                  Longitude
                </label>
                <input
                  type="number"
                  step={0.001}
                  name="longitude"
                  placeholder="Longitude"
                  id="long"
                  className="p-4 flex-1 outline-none border bg-gray-50 rounded-md"
                  value={store?.coordinates[1]}
                  onChange={getField}
                />
              </div>
              <div className="flex-1 flex flex-col space-y-2">
                <label
                  htmlFor="deliveryRadius"
                  className="text-sm text-gray-500"
                >
                  Radius Wise
                </label>
                <input
                  type="number"
                  step={0.001}
                  name="deliveryRadius"
                  placeholder="Radius"
                  id="deliveryRadius"
                  className="p-4 flex-1 outline-none border bg-gray-50 rounded-md"
                  value={store?.deliveryRadius}
                  onChange={getField}
                />
              </div>
            </div>
          </div>

          <div className="flex gap-2 w-full">
            <div className="flex-1 flex flex-col space-y-2">
              <label htmlFor="pinCode" className="text-sm text-gray-500">
                Pin Code
              </label>
              <input
                type="text"
                placeholder="Pin Code"
                className="p-4 flex-1 outline-none border bg-gray-50 rounded-md"
                name="pinCode"
                id="pinCode"
                value={store.pinCode}
                onChange={getField}
              />
            </div>

            <div className="flex-1 flex flex-col space-y-2">
              <label htmlFor="licenseCode" className="text-sm text-gray-500">
                License Code
              </label>
              <input
                type="text"
                className="p-4 outline-none flex-1 border bg-gray-50 rounded-md"
                name="licenseCode"
                id="licenseCode"
                value={store.licenseCode}
                placeholder="License Code"
                onChange={getField}
              />
            </div>

            <label
              htmlFor="photo"
              className="border bg-gray-50 rounded-md  cursor-pointer  flex  mt-7 justify-center text-sm text-gray-500  px-2 items-center gap-2"
            >
              <HiOutlineCloudUpload /> <p>Upload Image</p>
            </label>
            <input
              className="p-2 bg-white  hidden"
              type="file"
              accept="image/*"
              id="photo"
              name="photo"
              onChange={(e) => {
                if (e.target.files === null) {
                  return;
                }
                uploadFile({
                  file: e.target.files[0],
                  folderName: "stores/" + new Date().getTime().toString(),
                  setData: (url) =>
                    setStore((prev) => ({ ...prev, photo: url })),
                  setResponse,
                });
              }}
            />
          </div>

          <textarea
            rows={4}
            name="description"
            className="p-2 resize-none rounded-md outline-none border bg-gray-50 "
            value={store.description}
            placeholder="Description"
            onChange={getField}
          />

          <div className="flex gap-2 w-full ">
            <div className="flex flex-col w-1/3 space-y-2">
              <label className="text-sm text-gray-500" htmlFor="commissionRate">
                {" "}
                Commission Rate
              </label>
              <input
                type="number"
                className="p-4 outline-none border bg-gray-50 rounded-md"
                min={0.01}
                step={0.01}
                value={store.commissionRate}
                name="commissionRate"
                placeholder="Commission Rate"
                onChange={getField}
              />
            </div>

            <div className="flex flex-col w-1/3 space-y-2">
              <label className="text-sm text-gray-500" htmlFor="opensAt">
                Opens At
              </label>
              <input
                className="p-4 outline-none border bg-gray-50 rounded-md"
                type="time"
                name="opensAt"
                id="opensAt"
                value={store.opensAt}
                onChange={getField}
              />
            </div>
            <div className="flex flex-col w-1/3 space-y-2">
              <label className="text-sm text-gray-500" htmlFor="closesAt">
                Closes At
              </label>
              <input
                type="time"
                className="p-4 outline-none border bg-gray-50 rounded-md"
                name="closesAt"
                id="closesAt"
                value={store.closesAt}
                onChange={getField}
              />
            </div>
          </div>

          <button
            type="submit"
            className="bg-green-600 text-sm font-medium text-white p-5 rounded w-full"
          >
            {store?.edit ? "Update Store" : "Add Store"}
          </button>
        </form>

        {response.open && (
          <Feedback {...response} close={() => setResponse({ open: false })} />
        )}
      </div>

      <AnimatePresence>
        {locationModal && (
          <motion.div className="absolute top-0 left-0 right-0 bottom-0 bg-white/50 backdrop-blur-sm z-30 py-10">
            <div className="w-1/3  mx-auto mb-5">
              <button
                onClick={() => setShowLocationModal(false)}
                className="bg-gray-100 p-4 rounded-full"
              >
                <IoIosCloseCircle color="red" size={20} />
              </button>
            </div>
            <div className="w-1/3 h-[50vh] bg-white mx-auto p-5 border shadow rounded-md">
              <CompletePlaces
                value={place}
                onChange={setPlace}
                onSelect={setAddress}
                apiKey="AIzaSyA-a5Toku6TZnzEUwSSWHHkvGSkpMvwrMo"
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: "Search Places ...",
                        className:
                          "location-search-input bg-gray-50 w-full p-2",
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active p-2"
                          : "suggestion-item p-2";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </CompletePlaces>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}
