import Editor from "react-quill";
import "react-quill/dist/quill.snow.css";
import { motion } from "framer-motion";
import { useState } from "react";
import { addDoc } from "../api";
import { Feedback, Spin } from "./Feedback";
import _ from "lodash";

var toolbarOptions = [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike', 'image'],        // toggled buttons
    ['blockquote'],

  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'align': [] }],
  
    ['clean']                                         // remove formatting button
  ]


export default function Messaging({ users }) {
  const [message, setMessage] = useState({
    type: "Email",
    subject: "",
    target: "Users",
  });
  const [loading, setLoading] = useState(false);

  const [content, setContent] = useState(null);
  const [response, setResponse] = useState({
    open: false,
    type: "",
    message: "",
  });

  const filterNodes = () => {
    let emails = [],
      phones = [],
      ids = [];
    for (var usr of users) {
      emails.push(usr.email);
      phones.push(usr.phone);
      ids.push(usr._id);
    }

    const results = [_.compact(emails), _.compact(phones), _.compact(ids)];

    return message.type === "Email"
      ? results[0]
      : message.type === "SMS"
      ? results[1]
      : results[2];
  };
  const sendMessage = async (e) => {
    setLoading(true);
    const target = filterNodes();
    await addDoc({
      path: message.type === "SMS" ? "/msg/send-sms" : "/msg/send-mail",
      data: {
        subject: message.subject,
        target,
        content,
      },
      setResponse,
    });

    setLoading(false);
    setContent(null)
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="bg-black/20 transition-all backdrop-blur-sm absolute -top-10 left-0 right-0 bottom-0 z-10"
    >
      <motion.div
        initial={{ y: "100vh" }}
        animate={{ y: 0 }}
        exit={{ y: "100vh" }}
        className="fixed transition-all right-5  bottom-10 w-[550px] rounded-md flex-1 max-h-[80vh] bg-white shadow-lg flex flex-col space-y-5 p-5 "
      >
        <div className="flex flex-col space-y-2 ">
          <p className="text-xl font-medium leading-relaxed text-gray-700">
            Compose Message
          </p>
          <p className="text-sm leading-relaxed text-gray-400">
            Conveniently send message across to users based on the targeted
            audience and platform specification
          </p>
        </div>
        <div className="flex gap-2">
          <select
            required
            value={message.target}
            onChange={(e) =>
              setMessage((prev) => ({ ...prev, target: e.target.value }))
            }
            className="p-2 w-1/2 border outline-none bg-gray-50"
          >
            <option value="">Select Target</option>
            <option value="Users">All Users</option>
            <option value="Riders">Rider</option>
          </select>
          <select
            required
            value={message.type}
            onChange={(e) =>
              setMessage((prev) => ({ ...prev, type: e.target.value }))
            }
            className="p-2 w-1/2 bg-gray-50 border outline-none"
          >
            <option value="">Message Type</option>
            <option value="Email">Email</option>
            <option value="SMS">SMS</option>
            <option value="OTA">In App</option>
          </select>
        </div>
        {message.type !== "SMS" && (
          <input
            type="text"
            value={message.subject}
            required
            placeholder="Enter Subject"
            onChange={(e) =>
              setMessage((prev) => ({ ...prev, subject: e.target.value }))
            }
            className="w-full p-2 bg-gray-50 outline-none border"
          />
        )}
        <Editor
        value={content}
        modules={{toolbar:toolbarOptions}}
          className=" resize-none border-none ring-0"
          theme="snow"
          placeholder="Type your message here..."
          onChange={setContent}
        />
        <button
          onClick={sendMessage}
          className="w-fit mt-5 py-2 px-5 bg-indigo-500 rounded-md text-white"
        >
          {loading ? <Spin /> : "Send Message"}
        </button>
      </motion.div>
      {response.open && (
        <Feedback {...response} close={() => setResponse({ open: false })} />
      )}
    </motion.div>
  );
}
