import React, { useEffect, useState } from "react";
import { readDocs, updateDoc } from "../api";
import { motion } from "framer-motion";
import { HiMail } from "react-icons/hi";
import { HiArrowLeft, HiPencil, HiPhone, HiXCircle } from "react-icons/hi2";
import moment from "moment";
import _ from "lodash";
import { Feedback } from "./Feedback";

export default function StoreProfile({ store, setStore, setShow }) {
  const [orders, setOrders] = useState([]);
  const [foods, setFoods] = useState([]);
  const [response, setResponse] = useState({open:false, type:'', message:''})

  useEffect(() => {
    readDocs({
      path: `/order/store/${store?._id}`,
      setData: setOrders,
    });

    readDocs({
      path: `/food/store/${store?._id}`,
      setData: setFoods,
    });
  }, [store]);

  const getOrders = (food_id) => {
    let foodOrders = 0;
    for (var fd of orders) {
      const count = _.filter(fd.items, (itm) => itm._id === food_id).length;
      foodOrders += count;
    }

    return foodOrders;
  };

  const setStatus = status => {
    updateDoc({
      path:"/store", 
      id: store?._id,
      data:{isVerified:status}, 
      setResponse
    })
  }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex fixed top-0 left-0 transition-all z-50 w-screen h-screen"
    >
      <div
        onClick={() => setStore(null)}
        className="flex-1 bg-gray-300/30 backdrop-blur-sm"
      ></div>
      <div className="lg:w-3/4 md:w-full  h-screen overflow-y-auto relative bg-white flex flex-col ">
        <div
          className="flex flex-col relative  gap-2 p-2 md:h-[50vh] lg:h-[40vh] backdrop-blur-sm bg-cover"
          style={{
            backgroundImage: `linear-gradient(70deg, rgba(40, 50, 50, 0.4), rgba(80, 34, 70, 0.6)), url(${store?.photo})`,
          }}
        >
          <div className="flex-1"></div>
          <div className="flex flex-row items-end">
            <div className="flex w-1/2 flex-col gap-1 text-gray-100 px-5">
              <img
                alt={store?.name}
                src={store?.photo}
                className="h-20 w-20 bg-gray-400 rounded-full object-cover border-white border-2"
              />
              <h1 className="text-2xl leading-relaxed font-bold">
                {store?.name}
              </h1>
              <div className="flex flex-row items-center space-x-10">
                <div className="flex items-center space-x-2 text-xs">
                  <HiMail />
                  <p>{store?.email}</p>
                </div>
                <div className="flex items-center space-x-2 text-xs">
                  <HiPhone />
                  <p>{store?.phone}</p>
                </div>
              </div>
              <h1 className="text-xs my-2 text-gray-300">
                {store?.fullAddress} | Commission Rate :{" "}
                {Number(store?.commissionRate * 10).toFixed(1)}%
              </h1>
              <div className="flex gap-3">
                <h1 className="text-xs my-2 bg-green-500/50 px-3 py-1">
                  Opens At: {store?.opensAt}
                </h1>
                <h1 className="text-xs my-2 bg-red-500/50 px-3 py-1">
                  Closes At: {store?.closesAt}
                </h1>
              </div>
            </div>
            {!store?.isVerified && (
              <div className="border-l flex items-center justify-center flex-1 border-gray-200/50 p-5 h-1/2">
                <div className="flex space-x-2  flex-1">
                  <div className="flex flex-1 flex-col rounded-md bg-white p-2">
                    <p className="font-semibold">Store Status</p>
                    <p className="text-xs py-2">
                      This store has not been approved yet.
                    </p>
                    <div className="flex gap-2">
                      <button onClick={() => setStatus(true)} className="p-1 text-sm rounded-md text-white bg-green-500">
                        Approve
                      </button>
                      <button onClick={() => setStatus(false)} className="p-1 text-sm rounded-md text-white bg-red-500">
                        Decline
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="absolute top-0 flex gap-2 right-2  py-3">
            <button
              onClick={() => {
                setStore({ ...store, edit: true });
                setShow(true);
              }}
              className="px-1 cursor-pointer  flex items-center justify-center rounded-md py-2 bg-green-100/50 w-10 h-10 text-white"
            >
              <HiPencil size={20} />
            </button>
            <button
              onClick={() => setStore(null)}
              className="px-1 cursor-pointer flex items-center justify-center rounded-md py-2 bg-red-500/50 w-10 h-10 text-white"
            >
              <HiXCircle size={20} />
            </button>
          </div>
        </div>

        <div className="flex-1 px-5  flex-col space-y-5 py-5">
          <div className="flex flex-col space-y-2">
            <h2 className="text-xl font-medium ">Foods ({foods?.length})</h2>
            <p className="text-sm text-gray-500">
              List of dish servered by the store to customers
            </p>
          </div>
          <table className="table-auto w-full mt-2">
            <thead>
              <tr className="border-y bg-gray-200">
                <th className="text-left font-semibold p-2 text-sm text-gray-500">
                  Food Name
                </th>
                <th className="text-left font-semibold p-2 text-sm text-gray-500">
                  Orders
                </th>
                <th className="text-left font-semibold p-2 text-sm text-gray-500">
                  Addons
                </th>
                <th className="text-left font-semibold p-2 text-sm text-gray-500">
                  Price
                </th>
                <th className="text-left font-semibold p-2 text-sm text-gray-500">
                  Created At
                </th>
              </tr>
            </thead>
            <tbody>
              {foods?.map((food, idx) => (
                <tr
                  className="border-b py-2 cursor-pointer"
                  key={idx}
                  onClick={() => null}
                >
                  <td className="p-2 text-sm py-3 text-green-700">
                    {food.name}
                  </td>
                  <td
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      // navigate to user info
                    }}
                    className="p-2 text-sm py-3 text-blue-800"
                  >
                    #{getOrders(food._id)}
                  </td>
                  <td className="p-2 text-sm py-3">{food.addons?.length}</td>
                  <td className="p-2 text-sm py-3">
                    {store?.currencySymbol}
                    {food?.price}
                  </td>
                  <td className="p-2 text-sm py-3 font-light text-gray-600">
                    {moment(food.createdAt).from()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Usage History */}
        <div className="flex flex-col pt-2"></div>

        <button
          onClick={() => setStore(null)}
          className="bg-white z-20 absolute mx-5 mt-5 h-10 w-10 rounded-full flex items-center justify-center"
        >
          <HiArrowLeft size={18} />
        </button>
      </div>

      {response.open && <Feedback {...response}  close={() => setResponse({open:false})}/>}
    </motion.div>
  );
}
