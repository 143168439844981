import React, { useState } from 'react'
import { HiOutlineEnvelope, HiCheckCircle } from "react-icons/hi2";
import logo from "../assets/images/logo.png";
import { Feedback } from '../components/Feedback';
import { Link, useNavigate } from "react-router-dom";

export default function Forgotpwd() {

    const [details, setDetails] = useState({ email: ""});
    //eslint-disable-next-line
    const navigate = useNavigate(); 
    const [response, setResponse] = useState({
        open: false,
        message: "",
        type: "error",
    });


    return (
        <div className="flex flex-1 h-screen overflow-hidden w-screen">
            <div className="w-2/3 items-center justify-center flex flex-col space-y-6 bg-white">
                <div className="flex w-1/3 flex-col-reverse items-center justify-center relative">
                    <div className="flex flex-col space-y-4 items-center justify-center">
                        <p className="text-3xl font-bold">Forgot password</p>
                        <p className="text-gray-500 text-center text-sm">Please enter your adminstrative email address to recive a one-time reset link.</p>
                    </div>

                    <img
                        src={logo}
                        alt="Eyepa Logo"
                        className="w-32 h-32  object-cover rounded-full  left-50 "
                    ></img>
                </div>
                <div className="flex items-center justify-center w-1/2 ">
                    <form
                        onSubmit={null}
                        action="#"
                        className="flex flex-col  justify-center items-center gap-3 w-full   p-2"
                    >
                        <div className="flex flex-row items-center border py-6 px-3 pt-7 rounded-md w-3/4 relative">
                            <HiOutlineEnvelope size={20} />
                            <input
                                id="email"
                                className=" ml-2 focus:border-0 focus:ring-0 placeholder:text-transparent transition focus:outline-0 flex-1 peer/email"
                                type="email"
                                onChange={(e) => setDetails({...details, email: e.target.value})}
                                placeholder="Store ID"
                            />

                            <HiCheckCircle className="border border-white h-5 w-5 text-green-600" />


                            <label
                                htmlFor="email"
                                className="absolute text-gray-600 left-0 ml-9 -translate-y-5 bg-white px-1 text-xs duration-100 peer-focus/email:text-xs peer-focus/email:-translate-y-5 ease-linear peer-placeholder-shown/email:translate-y-0 peer-placeholder-shown/email:text-base peer-placeholder-shown/email:text-gray-500 "
                            >
                               Email address
                            </label>
                        </div>


                        <div className="flex justify-between w-3/4 flex-col space-y-2 items-center mt-2">
                            <button type="submit" className="bg-yellow-500 w-full p-4 rounded-md font-medium text-white text-sm">
                                Send Link
                            </button>
                            <Link to="/login" className="text-sm leading-relaxed text-gray-400">Back to login</Link>
                        </div>
                    </form>
                </div>
            </div>
            {response.open && (
                <Feedback {...response} close={() => setResponse({ open: false })} />
            )}
            <div className="flex-1 bg-welcome bg-cover"></div>
        </div>
    )
}
