import React, { useEffect, useState } from "react";
import { addDoc, readDocs, tempImage } from "../api";
import { RiUser4Line } from "react-icons/ri";
import { Feedback } from "../components/Feedback";
import Header from "../components/Header";
import {
  HiCheckCircle,
  HiOutlineEnvelope,
  HiPhone,
  HiPlusSmall,
  HiUser,
} from "react-icons/hi2";
import moment from "moment/moment";
import { FaAddressCard } from "react-icons/fa";
import { uploadFile } from "../api";
import { AnimatePresence, motion } from "framer-motion";
import { HiGlobeAlt, HiTruck } from "react-icons/hi";
import { countryListAllIsoData } from "../assets/flags";
import { LuUploadCloud } from "react-icons/lu";
import { useOutletContext } from "react-router-dom";

export default function Riders() {
  const [rider, setRider] = useState(null);
  const [isNewRider, setIsNewRider] = useState(false);
  const [riders, setRiders] = useState([]);
  //eslint-disable-next-line
  const [orders, setOrders] = useState([]);

  const { country } = useOutletContext();

  useEffect(() => {
    readDocs({
      path: `/rider/all/${country?.name}`,
      setData: setRiders,
    });
    readDocs({
      path: `/orders/all/${country?.name}`,
      setData: setOrders,
    });
  }, [country]);

  return (
    <div className="flex-1 p-5">
      <div className="mb-5 flex flex-row justify-between items-center">
        <Header
          title={"Riders"}
          description="List of all dispatch riders registered on your system."
        />
        <button
          onClick={() => setIsNewRider(!isNewRider)}
          className="p-2 bg-red-500 rounded-full text-white"
        >
          <HiPlusSmall size={20} color={"white"} />
        </button>
      </div>
      <div className="flex flex-wrap gap-2 overflow-y-auto">
        {riders?.map((info, idx) => (
          <Rider key={idx} info={info} open={() => setRider(info)} />
        ))}
      </div>

      <AnimatePresence>
        {rider !== null && (
          <RiderInformation info={rider} close={() => setRider(null)} />
        )}
        {isNewRider && <RegisterRider close={() => setIsNewRider(false)} />}
      </AnimatePresence>
    </div>
  );
}

function RegisterRider({ close }) {
  const [showCountryList, setShowCountryList] = useState(false);
  const [response, setResponse] = useState({
    message: "",
    type: "",
    open: false,
  });

  const [rider, setRider] = useState({
    name: "",
    email: "",
    country: "",
    phone: "",
    photo: null,
    vehiclePhoto: null,
    numberPlate: "",
    insuranceID: "",
    idType: "",
    idNumber: "",
  });

  const setField = (e) => {
    setRider((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const registerRider = async (e) => {
    e.preventDefault();
    await addDoc({
      path: "/rider",
      data: rider,
      setResponse,
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="absolute top-0 flex justify-center items-center left-0 right-0 bottom-0 cursor-pointer bg-white/50 backdrop-blur-sm z-10"
    >
      <div className="h-[95vh] z-10 overflow-y-auto border border-gray-200 flex-col shadow-sm bg-white border-x rounded-lg w-5/6 lg:w-2/4 mx-auto flex py-10">
        <div className="flex-1 flex flex-col space-y-5 items-center">
          <form
            action="#"
            onSubmit={registerRider}
            className="w-[80%] flex flex-col space-y-5 items-center justify-center"
          >
            <div className="flex flex-col items-center justify-center space-y-2 pb-5">
              <h3 className="text-xl font-bold">Register Rider</h3>
              <p className="text-sm text-gray-400 font-light">
                Add a new rider to your family of riders with on click.
              </p>
            </div>
            {rider?.photo !== null ? (
              <img
                src={rider?.photo}
                alt="Rider"
                className="w-36 h-36 object-cover rounded-full"
              />
            ) : (
              <label
                htmlFor="photo"
                className={`h-36 w-36 shrink-0 border rounded-full border-gray-300 cursor-pointer transition-all delay-75 duration-100 hover:border-gray-500 flex flex-col  items-center justify-center border-dashed`}
              >
                <LuUploadCloud
                  size={30}
                  color={rider?.photo === null ? "gray" : "green"}
                />
                <p className="text-xs text-gray-400 w-2/3 text-center">
                  {rider?.photo === null
                    ? "Image Uploaded"
                    : "Please upload the mage of the rider"}
                </p>
                <input
                  accept="image/*"
                  onChange={(e) => {
                    if (e.target.files === null) {
                      return;
                    }
                    uploadFile({
                      file: e.target.files[0],
                      folderName: "riders/" + new Date().getTime().toString(),
                      setData: (url) =>
                        setRider((prev) => ({ ...prev, photo: url })),
                      setResponse,
                    });
                  }}
                  className="hidden"
                  type="file"
                  id="photo"
                />
              </label>
            )}

            <div className="flex flex-row items-center border py-6 px-3 pt-7 rounded-md w-full relative">
              <HiUser size={20} />
              <input
                id="name"
                className=" ml-2 focus:border-0 focus:ring-0 placeholder:text-transparent transition focus:outline-0 flex-1 peer/name"
                type="text"
                name="name"
                value={rider?.name}
                onChange={setField}
                required
                placeholder="Name... "
              />
              <label
                htmlFor="name"
                className="absolute text-gray-600 left-0 ml-9 -translate-y-5 bg-white px-1 text-xs duration-100 peer-focus/name:text-xs peer-focus/name:-translate-y-5 ease-linear peer-placeholder-shown/name:translate-y-0 peer-placeholder-shown/name:text-base peer-placeholder-shown/name:text-gray-500 "
              >
                Full Name
              </label>
            </div>

            <div className="flex gap-2 w-full">
              <div className="flex flex-row items-center border py-6 px-3 pt-7 rounded-md w-1/2 relative">
                <HiPhone size={20} />
                <input
                  id="phone"
                  className=" ml-2 focus:border-0 focus:ring-0 placeholder:text-transparent transition focus:outline-0 flex-1 peer/phone"
                  type="tel"
                  name="phone"
                  value={rider?.phone}
                  onChange={setField}
                  placeholder="Phone"
                />
                <label
                  htmlFor="phone"
                  className="absolute text-gray-600 left-0 ml-9 -translate-y-5 bg-white px-1 text-xs duration-100 peer-focus/phone:text-xs peer-focus/phone:-translate-y-5 ease-linear peer-placeholder-shown/phone:translate-y-0 peer-placeholder-shown/phone:text-base peer-placeholder-shown/phone:text-gray-500 "
                >
                  Phone Number
                </label>
              </div>

              <div className="flex flex-row items-center border py-6 px-3 pt-7 rounded-md w-1/2 relative">
                <HiOutlineEnvelope size={20} />
                <input
                  id="email"
                  className=" ml-2 focus:border-0 focus:ring-0 placeholder:text-transparent transition focus:outline-0 flex-1 peer/email"
                  type="email"
                  value={rider?.email}
                  name="email"
                  onChange={setField}
                  required
                  placeholder="Email"
                />

                <HiCheckCircle className="border border-white h-5 w-5 text-green-600" />
                <label
                  htmlFor="email"
                  className="absolute text-gray-600 left-0 ml-9 -translate-y-5 bg-white px-1 text-xs duration-100 peer-focus/email:text-xs peer-focus/email:-translate-y-5 ease-linear peer-placeholder-shown/email:translate-y-0 peer-placeholder-shown/email:text-base peer-placeholder-shown/email:text-gray-500 "
                >
                  Email address
                </label>
              </div>
            </div>


            <div className="w-full flex flex-col space-y-2">
              <div className="flex flex-row items-center border py-6 px-3 pt-7 rounded-md w-full relative">
                {!rider?.country ? (
                  <HiGlobeAlt size={20} />
                ) : (
                  <img
                    className="h-5"
                    src={`https://flagcdn.com/48x36/${rider?.country.code?.toLowerCase()}.png`}
                    alt="country_flag"
                  />
                )}
                <input
                  id="country"
                  className=" ml-2 focus:border-0 focus:ring-0 placeholder:text-transparent transition focus:outline-0 flex-1 peer/country"
                  type="text"
                  placeholder="Country"
                  name="country"
                  value={rider?.country}
                  onChange={setField}
                  onFocus={(e) => setShowCountryList(true)}
                />

                <label
                  htmlFor="country"
                  className="absolute text-gray-600 left-0 ml-9 -translate-y-5 bg-white px-1 text-xs duration-100 peer-focus/country:text-xs peer-focus/country:-translate-y-5 ease-linear peer-placeholder-shown/country:translate-y-0 peer-placeholder-shown/country:text-base peer-placeholder-shown/country:text-gray-500 "
                >
                  {rider?.country?.name || "Select Country"}
                </label>
              </div>

              {showCountryList && (
                <div className="bg-white max-h-48 border rounded-md overflow-hidden p-3 overflow-y-auto space-y-4">
                  {countryListAllIsoData
                    .filter(({ name }, index) =>
                      name
                        .toLowerCase()
                        .startsWith(rider?.country.toLowerCase())
                    )
                    .map((data, index) => (
                      <div
                        onClick={(e) => {
                          setRider((prev) => ({ ...prev, country: data.name }));

                          setShowCountryList(false);
                        }}
                        key={index}
                        className="flex items-center space-x-5 hover:bg-gray-200 hover:p-2 transition-all rounded"
                      >
                        <img
                          className="h-5"
                          src={`https://flagcdn.com/48x36/${data.code.toLowerCase()}.png`}
                          alt="Country"
                        />
                        <p>{data.name}</p>
                      </div>
                    ))}
                </div>
              )}
            </div>

            
            <div className="flex gap-2 w-full">
              <div className="flex flex-row items-center border py-6 px-3 pt-7 rounded-md w-1/2 relative">
                <RiUser4Line size={20} />
                <input
                  id="idType"
                  className=" ml-2 focus:border-0 focus:ring-0 placeholder:text-transparent transition focus:outline-0 flex-1 peer/idType"
                  type="text"
                  name="idType"
                  value={rider?.idType}
                  onChange={setField}
                  placeholder="ID Type"
                />
                <label
                  htmlFor="idType"
                  className="absolute text-gray-600 left-0 ml-9 -translate-y-5 bg-white px-1 text-xs duration-100 peer-focus/idType:text-xs peer-focus/idType:-translate-y-5 ease-linear peer-placeholder-shown/idType:translate-y-0 peer-placeholder-shown/idType:text-base peer-placeholder-shown/idType:text-gray-500 "
                >
                  ID Type
                </label>
              </div>
              <div className="flex flex-row items-center border py-6 px-3 pt-7 rounded-md w-1/2 relative">
                <FaAddressCard size={20} />
                <input
                  id="idNumber"
                  className=" ml-2 focus:border-0 focus:ring-0 placeholder:text-transparent transition focus:outline-0 flex-1 peer/idNumber"
                  type="text"
                  name="idNumber"
                  value={rider?.idNumber}
                  onChange={setField}
                  placeholder="ID Type"
                />
                <label
                  htmlFor="idNumber"
                  className="absolute text-gray-600 left-0 ml-9 -translate-y-5 bg-white px-1 text-xs duration-100 peer-focus/idNumber:text-xs peer-focus/idNumber:-translate-y-5 ease-linear peer-placeholder-shown/idNumber:translate-y-0 peer-placeholder-shown/idNumber:text-base peer-placeholder-shown/idNumber:text-gray-500 "
                >
                  ID Number
                </label>
              </div>
            </div>


            <div className="flex items-center w-full space-x-2">
              <div className="flex flex-row items-center border py-6 px-3 pt-7 rounded-md w-full relative">
                <HiTruck size={20} />
                <input
                  id="bike"
                  name="numberPlate"
                  value={rider?.numberPlate}
                  onChange={setField}
                  className=" ml-2 focus:border-0 focus:ring-0 placeholder:text-transparent transition focus:outline-0 flex-1 peer/bike"
                  type="text"
                  placeholder="Number Plate"
                />
                <label
                  htmlFor="bike"
                  className="absolute text-gray-600 left-0 ml-9 -translate-y-5 bg-white px-1 text-xs duration-100 peer-focus/bike:text-xs peer-focus/bike:-translate-y-5 ease-linear peer-placeholder-shown/bike:translate-y-0 peer-placeholder-shown/bike:text-base peer-placeholder-shown/bike:text-gray-500 "
                >
                  Number Plate
                </label>
              </div>

              <label
                htmlFor="vehiclePhoto"
                className={`h-20 w-full border border-gray-300 cursor-pointer transition-all delay-75 duration-100 hover:border-gray-500 rounded-md flex flex-col  items-center justify-center border-dashed`}
              >
                <LuUploadCloud
                  size={30}
                  color={rider.vehiclePhoto ? "green" : "gray"}
                />
                <p className="text-sm text-gray-600">
                  {rider.vehiclePhoto 
                    ? "Image Uploaded"
                    : "Upload Vehicle Photo"}
                </p>
                <input
                  accept="image/*"
                  onChange={(e) => {
                    if (e.target.files === null) {
                      return;
                    }
                    uploadFile({
                      file: e.target.files[0],
                      folderName: "vehicles/" + new Date().getTime().toString(),
                      setData: (url) =>
                        setRider((prev) => ({ ...prev, vehiclePhoto: url })),
                      setResponse,
                    });
                  }}
                  className="hidden"
                  type="file"
                  id="vehiclePhoto"
                />
              </label>
            </div>

            <div className="flex flex-row items-center border py-6 px-3 pt-7 rounded-md w-full relative">
              <HiOutlineEnvelope size={20} />
              <input
                id="insurance"
                name="insuranceID"
                onChange={setField}
                className=" ml-2 focus:border-0 focus:ring-0 placeholder:text-transparent transition focus:outline-0 flex-1 peer/insurance"
                type="text"
                placeholder="insuranceID"
              />

              <label
                htmlFor="insurance"
                className="absolute text-gray-600 left-0 ml-9 -translate-y-5 bg-white px-1 text-xs duration-100 peer-focus/insurance:text-xs peer-focus/insurance:-translate-y-5 ease-linear peer-placeholder-shown/insurance:translate-y-0 peer-placeholder-shown/insurance:text-base peer-placeholder-shown/insurance:text-gray-500 "
              >
                Insurance Number
              </label>
            </div>

            <button
              type="submit"
              className="bg-green-500 w-full p-4 text-white rounded"
            >
              Register Rider
            </button>
          </form>

          {response.open && (
            <Feedback
              {...response}
              close={() => setResponse({ open: false })}
            />
          )}
        </div>
      </div>
      <div
        onClick={() => close()}
        className="flex-1 absolute top-0 left-0 right-0 bottom-0"
      ></div>
    </motion.div>
  );
}

function RiderInformation({ close, info }) {
  const { country } = useOutletContext();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={() => close()}
      className="absolute top-0 flex justify-center items-center left-0 right-0 bottom-0 cursor-pointer bg-white/50 backdrop-blur-sm z-10"
    >
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className="h-[95vh] overflow-y-auto border border-gray-200 flex-col shadow-sm bg-white border-x rounded-lg w-5/6 lg:w-2/4 mx-auto flex py-10"
      >
        <div className="flex-1 flex flex-col space-y-5 items-center justify-center">
          <div className="h-28 w-28 flex overflow-hidden border rounded-full">
            <img
              alt="profile_photo"
              className="flex-1 object-cover"
              src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
          </div>

          <div className="flex w-full flex-col space-y-1 items-center justify-center">
            <p className="text-base font-medium text-gray-800">{info?.name}</p>
            <p className="text-sm text-gray-500 w-1/3 text-center">
              {info?.country + " | " + info?.status}
            </p>
          </div>

          <div className="px-5 flex items-center justify-center border rounded bg-gray-50/50 backdrop-blur-sm">
            <div className="border-r p-5 px-10">
              <p className="text-xs text-gray-500 pb-1">Total Orders</p>
              <p className="text-lg font-bold">{info.orders?.length}</p>
            </div>
            <div className="p-5">
              <p className="text-xs text-gray-500 pb-1">Account Balance</p>
              <p className="text-lg font-bold">
                {country?.currencySymbol + info.earnings?.length}
              </p>
            </div>
          </div>

          <div className="w-full flex flex-col space-y-5 flex-1 pt-10">
            <div className="px-10 flex flex-col items-center">
              <p className="text-base font-medium text-gray-900">
                All Deliveries
              </p>
              <p className="text-sm text-gray-500 mt-1 text-center">
                List of all deliveries completed successfully by the rider.
              </p>
            </div>
            <div className="px-10">
              <table className="table-auto w-full mt-2">
                <thead>
                  <tr className="border-y bg-gray-200">
                    <th className="text-left font-semibold p-2 text-sm text-gray-500">
                      Order
                    </th>
                    <th className="text-left font-semibold p-2 text-sm text-gray-500">
                      Quantity
                    </th>
                    <th className="text-left font-semibold p-2 text-sm text-gray-500">
                      Pin
                    </th>
                    <th className="text-left font-semibold p-2 text-sm text-gray-500">
                      Income
                    </th>
                    <th className="text-left font-semibold p-2 text-sm text-gray-500">
                      Joined Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    className="border-b py-2 cursor-pointer"
                    key={`idx`}
                    onClick={() => null}
                  >
                    <td className="p-2 text-sm py-3 flex gap-3 flex-row items-center text-green-700">
                      <img
                        alt={"store?.name"}
                        src={
                          "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        }
                        className="w-10 object-cover rounded-md h-10 bg-green-500"
                      ></img>
                      <div>
                        <p className="font-bold">Salad Bowl XL</p>
                        <p className=" text-xs font-medium text-gray-400">
                          #6768398390
                        </p>
                      </div>
                    </td>
                    <td
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // navigate to user info
                      }}
                      className="p-2 text-sm py-3 text-blue-800"
                    >
                      #550
                    </td>
                    <td className="p-2 text-sm py-3">5</td>
                    <td className="p-2 text-sm py-3">799.00</td>
                    <td className="p-2 text-sm py-3 font-light text-gray-600">
                      {moment().from()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

const Rider = ({ open, info }) => (
  <div
    onClick={open}
    className="flex cursor-pointer  flex-col h-fit w-[48%] lg:w-[24%] bg-gray-50 border-blue-100 border shadow-md rounded-lg overflow-hidden"
  >
    <div
      className="w-full h-14 relative bg-black"
      style={{
        backgroundImage: `linear-gradient(70deg, rgba(40, 50, 50, 0.4), rgba(80, 34, 70, 0.8)), url(${"https://images.unsplash.com/photo-1621114957135-7f88c8447439?q=80&w=2831&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"})`,
      }}
    >
      <img
        src={info.photo || tempImage}
        alt="Rider"
        className="w-20 object-cover h-20 rounded-full absolute left-3 -bottom-8 border-4 border-white bg-green-500"
      ></img>
    </div>
    <div className="flex flex-col">
      <div className="flex px-2 justify-between pt-10">
        <div className="flex flex-col">
          <p className="font-semibold">{info?.name}</p>
          <p className="text-xs">{info?.email}</p>
        </div>
      </div>
      <div className="p-2 flex gap-3">
        <div className="flex flex-col p-2">
          <div className="flex gap-2 items-center">
            <p className="font-bold text-sm">{info.orders?.length}</p>{" "}
            <p className="text-gray-500 text-xs">Deliveries</p>
          </div>
        </div>
        <div className="flex flex-col p-2">
          <div className="flex gap-2 items-center">
            <p className="font-bold text-sm">4.5</p>{" "}
            <p className="text-gray-400 text-xs">Ratings</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);
