import React, { useEffect, useState } from "react";
import { readDocs } from "../api";
import { AnimatePresence } from "framer-motion";
import UserProfile from "../components/UserProfile";
import { useOutletContext } from "react-router-dom";


export default function Users() {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [orders, setOrders] = useState([]);

  const { country } = useOutletContext();

  useEffect(() => {
    readDocs({
      path: `/user/all/${country?.name}`,
      setData: setUsers,
    });

    readDocs({
      path: `/order/all/${country?.name}`,
      setData: setOrders,
    });
  }, [country?.name]);

  const filtered = () => {
    return users;
  };

  const getStats = (userId) => {
    const userOrders = orders.filter((ord) => ord.userID === userId);
    const cancelled = userOrders.filter(
      (ord) => ord.status === "Cancelled"
    ).length;
    const completed = userOrders.filter(
      (ord) => ord.status === "Completed"
    ).length;

    return [userOrders.length, completed, cancelled];
  };

  return (
    <div className="flex flex-col flex-1 p-5">
      <table className="w-full table-auto">
        <thead className="border-y bg-gray-200">
          <tr>
            <th className="text-left font-semibold p-2 text-sm text-gray-500">Name</th>
            <th className="text-left font-semibold p-2 text-sm text-gray-500">Email</th>
            <th className="text-left font-semibold p-2 text-sm text-gray-500">Phone</th>
            <th className="text-left font-semibold p-2 text-sm text-gray-500">Location</th>
            <th className="text-left font-semibold p-2 text-sm text-gray-500">Total Orders</th>
            <th className="text-left font-semibold p-2 text-sm text-gray-500">Cancelled</th>
            <th className="text-left font-semibold p-2 text-sm text-gray-500">Completed</th>
          </tr>
        </thead>
        <tbody>
          {filtered()?.map((user, idx) => (
            <User key={idx} user={user} setUser={setUser} getStats={getStats} />
          ))}
        </tbody>
      </table>

      <AnimatePresence>
        {user && <UserProfile user={user} setUser={setUser} />}
      </AnimatePresence>
    </div>
  );
}

const User = ({ user, getStats, setUser }) => {
  return (
    <tr className="border-b py-2 cursor-pointer" onClick={() => setUser(user)}>
      <td className="p-2 text-sm py-3 font-semibold">{user?.name}</td>
      <td className="p-2 text-sm py-3">{user?.email}</td>
      <td className="p-2 text-sm py-3">{user?.phone}</td>
      <td className="p-2 text-sm py-3">{user?.location?.city}</td>
      <td className="p-2 text-sm py-3">{getStats(user._id)[0]}</td>
      <td className="p-2 text-sm py-3 text-red-500">{getStats(user._id)[1]}</td>
      <td className="p-2 text-sm py-3">{getStats(user._id)[2]}</td>
    </tr>
  );
};
