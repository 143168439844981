import React, { useEffect, useState } from "react";
import { maxDate } from "../../functions";
import { Feedback } from "../../components/Feedback";
import { HiChevronDown } from "react-icons/hi2";
import { addDoc, adminID, deleteDoc, socket, updateDoc, readDocs } from "../../api";
import _ from "lodash";
import moment from "moment";
import { BsTrash } from "react-icons/bs";
import { TbEdit } from "react-icons/tb";

const PromoTypes = ["Percentage", "Money", "X for Y"];

export default function Promotions({ stores, country }) {
  const [response, setResponse] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const [code, setCode] = useState(0)
  const [showCreate, setShowCreate] = useState(false)

  const [promos, setPromos] = useState([]);

  socket.on(adminID, (pl) => {
    if (pl === "promo") {
      setCode(new Date().getSeconds());
    }
  });

  useEffect(() => {
    readDocs({
      path: `/promotion/all/${country?.name}?code=${code}`,
      setData: setPromos,
    });
  }, [code, country]);

  const [ids, setIDs] = useState([]);

  const [promo, setPromo] = useState({
    storeID: "",
    promoCode: "",
    country: country?.name,
    message: "",
    promoType: "",
    rate: 0.0,
    startsAt: "",
    endsAt: "",
    isOpen: true,
  });

  const [showSelectStore, setShowSelectStore] = useState(false);

  const getField = (e) => {
    setPromo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const createPromo = (e) => {
    e.preventDefault();
    if (promo?.edit) {
      updateDoc({
        path: "/promotion",
        id: promo._id,
        data: promo,
        setResponse,
      });

      setPromo((prev) => ({ ...prev, edit: false, _id: null }));
      return;
    } else {
      if (ids.length !== 0) {
        for (var of of ids) {
          addDoc({
            path: "/promotion",
            data: { ...promo, storeID: of },
            setResponse,
          });
        }
      }
    }
  };

  const deletePromo = (id) => {
    deleteDoc({
      path: "/promotion",
      id,
      setResponse,
    });
  };

  const addID = (checked, id) => {
    if (checked) {
      setIDs((prev) => [...prev, id]);
    } else {
      setIDs(_.filter(ids, (idx) => idx !== id));
    }
  };

  const findStore = (id) => _.find(stores, (st) => st._id === id)?.name;

  return (
    <div className="flex w-full p-3 px-5 ">

      <div className="flex-1 p-2">

        <div className="w-full flex items-center justify-between py-5">
          <p className="font-medium text-lg font-semibold">Manage Promotions.</p>
          <button onClick={() => setShowCreate(true)} className="bg-yellow-500 px-5 py-2 rounded text-sm text-white font-semibold">
            Create Promotions
          </button>
        </div>
        <div className="py-2 align-middle inline-block min-w-full">
          <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Store
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Code
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Rate
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Starts
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Ends
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {promos?.map((info, idx) => (
                  <tr key={info.email}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">

                        <div className="">
                          <div className="text-sm font-medium text-gray-900">{findStore(info.storeID)}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900"> {info.promoCode}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {info.rate}{" "}
                      {info?.promoType === "Percentage"
                        ? "%"
                        : country.currencySymbol}{" "}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{moment(info.startsAt).from()}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{moment(info.endsAt).from()}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex w-full items-center gap-2">
                        <button
                          onClick={() => { setShowCreate(true); deletePromo(info._id) }}
                        >
                          <BsTrash size={18} color="red" />
                        </button>
                        <button onClick={() => { setShowCreate(true); setPromo({ ...info, edit: true }) }}>
                          <TbEdit size={18} color="green" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {showCreate && <div onClick={e => setShowCreate(false)} className="bg-black/50 cursor-pointer top-0 left-0 bottom-0 right-0 absolute z-10 flex backdrop-blur-sm">
        <div className="w-1/3 mx-auto mt-10  bg-white h-fit rounded-md">
          <form
            onSubmit={createPromo}
            action="#"
            onClick={e => e.stopPropagation()}
            className="flex flex-col gap-3 p-5 overflow-y-auto"
          >
            <div className="flex flex-col">
              <label htmlFor="storeID">Store</label>
              <div
                onClick={() => setShowSelectStore(true)}
                className="flex cursor-pointer items-center justify-between border p-3 rounded-md"
              >
                <p>
                  {promo?.storeID
                    ? stores?.filter((store) => store._id === promo?.storeID)[0]
                      ?.name
                    : "Select Store"}
                </p>
                <HiChevronDown size={20} />
              </div>
              {showSelectStore && (
                <>
                  <div className="border absolute w-[24vw] z-20 bg-white/95 shadow-sm backdrop-blur-sm  rounded-md mt-4 flex flex-col h-72">
                    <div className=" flex flex-1 flex-col space-y-3">
                      {stores?.map((info, idx) => (
                        <div className="flex border-b pb-3 pt-1 w-full items-center gap-2">
                          <div
                            onClick={() => {
                              getField({
                                target: { name: "storeID", value: info._id },
                              });
                              setShowSelectStore(false);
                            }}
                            key={idx}
                            className=" w-2/3 mx-5  cursor-pointer"
                          >
                            <p className="text-gray-600"> {info.name}</p>
                          </div>
                          <div className="flex items-center  justify-center ">
                            <input
                              checked={ids.includes(info._id)}
                              onChange={(e) => addID(e.target.checked, info._id)}
                              type="checkbox"
                              className="w-4 outline-none h-4 rounded-full"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    onClick={() => setShowSelectStore(false)}
                    className="absolute top-0 left-0 right-0 bottom-0 z-10"
                  ></div>
                </>
              )}
            </div>

            <div className="w-full flex flex-col gap-2">
              <div className="flex flex-col w-full">
                <label htmlFor="message" className="text-sm">
                  Message
                </label>
                <textarea
                  required
                  type="text"
                  name="message"
                  id="message"
                  value={promo?.message}
                  onChange={getField}
                  placeholder="Message"
                  className="p-2 h-20 resize-none  bg-gray-50 rounded-md border outline-none"
                />
              </div>
            </div>

            <div className="flex flex-row items-center w-full">
              <div className="flex flex-col w-1/2 pr-1">
                <label htmlFor="promoCode" className="text-sm">
                  Promo Code
                </label>
                <input
                  required
                  type="text"
                  name="promoCode"
                  id="promoCode"
                  value={promo?.promoCode}
                  onChange={getField}
                  placeholder="promoCode"
                  className="p-2 bg-gray-50 rounded-md border outline-none"
                />
              </div>
              <div className="flex flex-col w-1/2 pl-1">
                <label htmlFor="rate" className="text-sm">
                  Discount Value
                </label>
                <input
                  required
                  name="rate"
                  id="rate"
                  min="0"
                  step={0.01}
                  value={promo?.rate}
                  type="number"
                  onChange={(e) =>
                    setPromo((prev) => ({
                      ...prev,
                      rate: parseFloat(e.target.value),
                    }))
                  }
                  placeholder="Discount Value"
                  className="p-2 bg-gray-50 rounded-md border outline-none"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label htmlFor="promoType" className="text-sm">
                Promotion Type
              </label>
              <select
                required
                id="promoType"
                name="promoType"
                value={promo?.promoType}
                onChange={getField}
                className="p-2 bg-gray-50 rounded-md border outline-none"
              >
                <option value="">Type</option>
                {PromoTypes.map((info, idx) => (
                  <option key={idx} value={info}>
                    {info}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex w-full gap-3">
              <div className="flex flex-col w-1/2">
                <label htmlFor="startsAt" className="text-sm">
                  Starts On
                </label>
                <input
                  type="date"
                  required
                  min={maxDate()}
                  name="startsAt"
                  id="startsAt"
                  value={promo?.startsAt.slice(0, 10)}
                  onChange={getField}
                  className="p-2 bg-gray-50 rounded-md border outline-none"
                />
              </div>
              <div className="flex flex-col w-1/2">
                <label htmlFor="endsAt" className="text-sm">
                  Ends At
                </label>
                <input
                  required
                  min={maxDate()}
                  type="date"
                  value={promo?.endsAt.slice(0, 10)}
                  onChange={getField}
                  name="endsAt"
                  id="endsAt"
                  className="p-2 bg-gray-50 rounded-md border outline-none"
                />
              </div>
            </div>
            <button
              className={`p-2 ${promo?.edit ? "bg-green-400" : "bg-yellow-500"
                } text-white rounded-md`}
            >
              {promo?.edit ? "Update Promotion" : "Create Promotion"}
            </button>
            {response.open && (
              <Feedback
                {...response}
                close={() => setResponse({ open: false })}
              />
            )}
          </form>
        </div>
      </div>}
    </div>
  );
}
